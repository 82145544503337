import {
  Dashboard,
  ListAlt,
  Receipt,
  Person,
  SettingsApplications,
  Contacts,
  HistoryEdu,
  Groups,
  Info,
  Storefront,
  Category,
  LocalOffer,
  Medication,
  QrCode2,
  Inventory,
  PeopleAlt,
} from "@mui/icons-material";
import colors from "./styles/colors";
const styles = {
  iconSize: {
    p: 0.2,
    fontSize: "22px",
    color: colors.secondary,
  },
};

const Navigation = [
  {
    label: "Dashboard",
    icon: <Dashboard sx={styles.iconSize} />,
    path: "/",
  },
  {
    label: "Users",
    icon: <Person sx={styles.iconSize} />,
    path: "/users",
    subPath: "/deleted-users",
  },
  {
    label: "Staff",
    icon: <PeopleAlt sx={styles.iconSize} />,
    path: "/staff",
    subPath: "/create-staff",
  },
  {
    label: "Orders",
    icon: <ListAlt sx={styles.iconSize} />,
    path: "/orders",
    subPath: "/order/:id",
  },
  {
    label: "Pharmacies",
    icon: <ListAlt sx={styles.iconSize} />,
    path: "/pharmacies",
  },
  {
    label: "Vouchers",
    icon: <Receipt sx={styles.iconSize} />,
    path: "/vouchers",
    subPath: "/edit-voucher",
  },
  {
    label: "Marketplace",
    isOpen: false,
    icon: <Storefront sx={styles.iconSize} />,
    subMenu: [
      {
        label: "Categories",
        icon: <Category sx={styles.iconSize} />,
        path: "/marketplace/categories",
      },
      {
        label: "Pharmacies",
        icon: <LocalOffer sx={styles.iconSize} />,
        path: "/marketplace/pharmacies",
      },
      {
        label: "Offers",
        icon: <Medication sx={styles.iconSize} />,
        path: "/marketplace/offers",
      },
      {
        label: "Products",
        icon: <QrCode2 sx={styles.iconSize} />,
        path: "/marketplace/products",
      },
      {
        label: "Orders",
        icon: <Inventory sx={styles.iconSize} />,
        path: "/marketplace/orders",
      },
      {
        label: "Vouchers",
        icon: <Receipt sx={styles.iconSize} />,
        path: "/marketplace/vouchers",
      },
    ],
  },
  {
    label: "Blogs",
    icon: <HistoryEdu sx={styles.iconSize} />,
    path: "/blogs",
    subPath: "/edit-blog",
  },
  {
    label: "Delivery Charges",
    icon: <SettingsApplications sx={styles.iconSize} />,
    path: "/app-setting",
  },
  {
    label: "Contact Details",
    icon: <Contacts sx={styles.iconSize} />,
    path: "/contact-detail",
  },
  {
    label: "About Us",
    icon: <Groups sx={styles.iconSize} />,
    path: "/about-us",
  },
  {
    label: "Terms & Conditions",
    icon: <Info sx={styles.iconSize} />,
    path: "/terms-conditions",
  },
];

export default Navigation;
