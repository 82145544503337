import { useState } from "react";
import { Close } from "@mui/icons-material";
import {
  Box,
  Dialog,
  DialogTitle,
  IconButton,
  Grid,
  Typography,
  Button
} from "@mui/material";
import { baseUrl } from "../../../axios";
import { Swiper, SwiperSlide } from "swiper/react";
//* Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
//* import required modules
import { Navigation } from "swiper";
import colors from "../../styles/colors";

function UserInfoDialog({ open, onClose, action, loading, userInfo }) {

  const [fetching, setFetching] = useState(false);
  const imgDownload = (url, name) => {
    if (!url) {
      throw new Error("Resource URL not provided! You need to provide one");
    }
    setFetching(true);
    fetch(url)
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        setFetching(false);
        const blobURL = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = blobURL;
        a.style = "display: none";

        if (name && name.length) a.download = name;
        document.body.appendChild(a);
        a.click();
      })
      .catch((error) => console.log(error));
  };

  return (
    <Dialog
      maxWidth="xs"
      open={open}
      sx={{
        "& .MuiDialog-paper": {
          width: "80%",
          height: "auto",
          borderRadius: 2,
          py: { xs: 2, md: 4 },
          px: { xs: 3, md: 6 },
        },
      }}
    >
      <IconButton
        data-testId="close-dialog"
        color="primary"
        onClick={() => onClose()}
        sx={{ position: "absolute", right: 13, top: 13 }}
      >
        <Close />
      </IconButton>
      <DialogTitle
        sx={{ textAlign: "center", fontSize: "18px", fontWeight: 700 }}
      >
        Documents
      </DialogTitle>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12}>
          <Swiper navigation={true} modules={[Navigation]}>
            {userInfo?.nic_front && (
              <SwiperSlide style={{ display: "flex", justifyContent: "center" }}>
                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "10px" }}>

                  <img
                    src={baseUrl + userInfo?.nic_front}
                    alt="Nic Front"
                    style={{ objectFit: "contain", height: "250px" }}
                  />
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Button disabled={fetching} onClick={() => imgDownload(baseUrl + userInfo?.nic_front, "card_front")} variant="contained" sx={{ backgroundColor: colors.secondary }}>Download</Button>
                  </Box>
                </Box>
              </SwiperSlide>
            )}
            {userInfo?.nic_back && (
              <SwiperSlide
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "10px" }}>
                  <img
                    src={baseUrl + userInfo?.nic_back}
                    alt="Nic Back"
                    style={{ objectFit: "contain", height: "250px" }}
                  />
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Button disabled={fetching} onClick={() => imgDownload(baseUrl + userInfo?.nic_back, "card_back")} variant="contained" sx={{ backgroundColor: colors.secondary }}>Download</Button>
                  </Box>
                </Box>
              </SwiperSlide>
            )}
            {userInfo?.insurance && (
              <SwiperSlide
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "10px" }}>
                  <img
                    src={baseUrl + userInfo?.insurance}
                    alt="Insurance"
                    style={{ objectFit: "contain", height: "250px" }}
                  />
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Button disabled={fetching} onClick={() => imgDownload(baseUrl + userInfo?.insurance, "insurance")} variant="contained" sx={{ backgroundColor: colors.secondary }}>Download</Button>
                  </Box>
                </Box>
              </SwiperSlide>
            )}
          </Swiper>
        </Grid>
      </Grid>
    </Dialog>
  );
}

export default UserInfoDialog;
