import React, { Fragment, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
//* Required Components
import { Layout } from "./../../layout/Layout";
import BreadCrumbs from "./../../components/BreadCrumbs/BreadCrumbs";
import ConfirmationDialog from "./../../components/Dialog/ConfirmationDialog";
import VoucherServices from "./../../apis/Voucher/VoucherServices";
import { Loading } from "./../../components/UI/Loader";
import { getDate } from "../../utils/DateFormate";
import { SecondaryButton } from "../../components/Button/Button";
import {
  ErrorToaster,
  SuccessToaster,
} from "./../../components/Toaster/Toaster";
import colors from "../../styles/colors";
//* MUI Imports
import { useTheme } from "@emotion/react";
import {
  KeyboardArrowLeft,
  KeyboardArrowRight,
  Create,
  DeleteForever,
} from "@mui/icons-material";
import {
  Paper,
  Box,
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  IconButton,
} from "@mui/material";

//* Table Pagination ActionsComponent
function TablePaginationActions(props) {
  const theme = useTheme;

  const { count, page, rowsPerPage, onPageChange } = props;

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
    </Box>
  );
}

const tableHead = [
  "Voucher Code",
  "Discount",
  "Usage Limit",
  "Validity Start",
  "Validity End",
  "Action",
];

const Voucher = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  // *For Loader
  const [loader, setLoader] = useState(true);
  const [vouchers, setVouchers] = useState([]);
  const [voucherId, setVoucherId] = useState("");
  const [count, setCount] = useState("");
  const [confirmDialog, setConfirmDialog] = useState(false);
  const role = localStorage.getItem("role");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getVouchers(newPage + 1, rowsPerPage);
  };

  const handleChangeRowsPerPage = (event) => {
    // setRowsPerPage(parseInt(event.target.value, 10));
    setRowsPerPage(Math.floor(event.target.value));
    setPage(0);
    getVouchers(page + 1, event.target.value);
  };

  //* Get All Vouchers
  const getVouchers = async (newPage, rowsPerPage) => {
    try {
      const { data, responseCode, status } =
        await VoucherServices.getAllVouchers(
          newPage ? newPage : 1,
          rowsPerPage ? rowsPerPage : ""
        );
      if (status === true && responseCode === 200) {
        setVouchers(data.result);
        setCount(data.count);
      }
    } catch (error) {
      ErrorToaster(error);
    } finally {
      setLoader(false);
    }
  };

  //* Delete vouchers
  const voucherDelete = async () => {
    try {
      const { data, message } = await VoucherServices.deleteVoucher(voucherId);
      if (data === null) return;
      SuccessToaster(message);
      getVouchers();
    } catch (error) {
      ErrorToaster(error);
    }
  };

  useEffect(() => {
    getVouchers();
  }, []);
  return (
    <Fragment>
      <ConfirmationDialog
        open={confirmDialog}
        onClose={() => setConfirmDialog(!confirmDialog)}
        action={voucherDelete}
        title={"Delete This Voucher"}
      />
      <Box
        sx={{
          backgroundColor: colors.lightGray,
          p: 3,
          borderRadius: "12px",
          width: "calc(100vw - 370px)",
        }}
      >
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          sx={{
            bgcolor: colors.white,
            p: 2,
            borderRadius: "12px",
          }}
        >
          <Grid item xs={6}>
            <Typography variant="body1" fontWeight="bold" color="initial">
              Vouchers List
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <BreadCrumbs currentPage={["Vouchers"]} />
          </Grid>
        </Grid>
        {loader ? (
          <Box sx={{ width: 1, textAlign: "center", pt: 5 }}>
            <Loading />
          </Box>
        ) : (
          <Fragment>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                bgcolor: colors.white,
                p: 2,
                borderRadius: "12px",
                textAlign: "right",
                mt: 2,
              }}
            >
              <SecondaryButton
                disabled={role !== "A" ? true : false}
                title="Create Voucher"
                onClick={() => navigate("/create-voucher")}
              />
            </Box>
            <TableContainer
              component={Paper}
              sx={{
                "&::-webkit-scrollbar": {
                  bgcolor: colors.white,
                  height: "8px",
                  borderRadius: "10px",
                  cursor: "pointer",
                },
                "&::-webkit-scrollbar-thumb": {
                  bgcolor: colors.primary,
                  borderRadius: "10px",
                  cursor: "pointer",
                },
                "&.MuiPaper-root": {
                  borderRadius: "12px",
                  mt: 2,
                  boxShadow: 0,
                },
              }}
            >
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow sx={{ bgcolor: colors.lightGray }}>
                    {tableHead.map((item, index) => (
                      <TableCell key={index} sx={{ fontWeight: "bold" }}>
                        {item}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {vouchers.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row">
                        {row.code}
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant="body2"
                          color="initial"
                          noWrap
                          sx={{ fontWeight: "bold" }}
                        >
                          <span style={{ fontSize: "10px" }}>AED</span>
                          {row.discount_amount
                            ? ` ${row.discount_amount}`
                            : "-"}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2" color="initial" noWrap>
                          {row.usage_limit ?? "-"}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2" color="initial" noWrap>
                          {getDate(row.valid_from)}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        {/* <Typography variant="body2" color="initial" noWrap>{getDate(row.valid_to)}</Typography> */}
                        <Typography variant="body2" color="initial" noWrap>
                          {row.valid_to.substr(0, 10)}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Box display="flex">
                          <Tooltip title="Edit">
                            <IconButton
                              disabled={role !== "A" ? true : false}
                              component={Link}
                              to={`/edit-voucher`}
                              state={{ data: row }}
                            >
                              <Create
                                sx={{
                                  color:
                                    role !== "A"
                                      ? colors.gray
                                      : colors.secondary,
                                }}
                              />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Delete">
                            <IconButton
                              disabled={role !== "A" ? true : false}
                              onClick={() => {
                                setVoucherId(row._id);
                                setConfirmDialog(!confirmDialog);
                              }}
                            >
                              <DeleteForever
                                sx={{
                                  color:
                                    role !== "A" ? colors.gray : colors.opal,
                                }}
                              />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Table sx={{ marginTop: "12px" }}>
              <TablePagination
                sx={{
                  borderBottom: "none",
                  bgcolor: colors.white,
                  borderRadius: "12px",
                }}
                rowsPerPageOptions={[10, 20, 30]}
                colSpan={12}
                count={+count}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    "aria-label": "rows per page",
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </Table>
          </Fragment>
        )}
      </Box>
    </Fragment>
  );
};

export default Voucher;
