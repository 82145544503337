import React, { Fragment, useEffect, useState } from 'react'
import { Layout } from './../../layout/Layout';
import { Typography, Box, Grid, Divider, InputAdornment, TextField, FormControl } from '@mui/material';
import colors from './../../styles/colors';
import BreadCrumbs from './../../components/BreadCrumbs/BreadCrumbs';
import { InputField, OutlineInputField } from './../../components/Fields/TextField';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import { SecondaryButton } from '../../components/Button/Button';
import { PrimaryButton } from './../../components/Button/Button';
import { useNavigate } from 'react-router-dom';
import SettingServices from './../../apis/Settings/SettingsServices';
import { ErrorToaster, SuccessToaster } from './../../components/Toaster/Toaster';
import { Loading } from './../../components/UI/Loader';
import { useForm } from 'react-hook-form';

const AppSettings = () => {
  const navigate = useNavigate();
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [setting, setSetting] = useState([]);
  const [loader, setLoader] = useState(true);
  const [enableBtn, setEnableBtn] = useState(false);

  const updateAppSettings = async (formData) => {
    try {
      let obj = {
        gst: formData.gst,
        vat: formData.vat,
        delivery_charges: Number(formData.delivery),
        free_delivery: Number(formData.freeDelivery),
      }
      const { data, message } = await SettingServices.updateAppSettings(obj);
      if (data === null) return
      SuccessToaster(message)
      appSettings()
    }
    catch (error) {
      ErrorToaster(error)
    }
  }

  const appSettings = async () => {
    try {
      const { data } = await SettingServices.appSettings()
      if (data === null) return
      setSetting(data.result)
    }
    catch (error) {
      ErrorToaster(error)
    }
    finally {
      setLoader(false)
    }
  }
  useEffect(() => {
    appSettings()
  }, [])
  return (
    <Fragment>
      <Box sx={{ backgroundColor: colors.lightGray, p: 3, borderRadius: "12px", width: "calc(100vw - 370px)" }}>
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          sx={{ bgcolor: colors.white, p: 2, borderRadius: "12px", }}
        >
          <Grid item xs={6}>
            <Typography variant="body1" fontWeight="bold" color="initial">Delivery Charges</Typography>
          </Grid>
          <Grid item xs={6} sx={{ display: "flex", justifyContent: "flex-end" }}>
            <BreadCrumbs currentPage={['Delivery Charges']} />
          </Grid>
        </Grid>
        {loader ? (
          <Box sx={{ width: 1, textAlign: "center", pt: 5 }}>
            <Loading />
          </Box>
        ) : (
          <Fragment>
            <Box sx={{ bgcolor: colors.white, p: 3, borderRadius: "12px", mt: 2 }}>
              <Typography component={"form"} onSubmit={handleSubmit(updateAppSettings)}>
                <Grid container spacing={2} alignItems="center">
                  {/* GST */}
                  {/* <Grid item xs={12} md={6}>
                    <InputField
                      defaultValue={setting.gst}
                      label="GST"
                      register={register("gst", {
                        required: 'Please fill all fields',
                        onChange: () => setEnableBtn(true),
                        pattern: {
                          message: 'Please fill all fields',
                        }
                      })}
                    />
                  </Grid> */}
                  {/* VAT */}
                  {/* <Grid item xs={12} md={6}>
                    <InputField
                      defaultValue={setting.vat}
                      label="VAT"
                      register={register("vat", {
                        required: 'Please fill all fields',
                        onChange: () => setEnableBtn(true),
                        pattern: {
                          message: 'Please fill all fields',
                        }
                      })}
                    />
                  </Grid> */}
                  {/* Delivery Charges */}
                  <Grid item xs={12} md={6}>
                    <InputField
                    type="number"
                      defaultValue={setting.delivery_charges}
                      label="Delivery Charges"
                      register={register("delivery", {
                        required: 'Please fill all fields',
                        onChange: () => setEnableBtn(true),
                        pattern: {
                          message: 'Please fill all fields',
                        }
                      })}
                    />
                  </Grid>
                  {/* Free Delivery  */}
                  <Grid item xs={12} md={6}>
                    <FormControl variant="standard" fullWidth  >
                      <TextField
                      type="number"
                      InputProps={{
                        inputProps: { min: 0 }
                      }}
                        size={"small"}
                        variant="outlined"
                        label="Free Delivery Amount"
                        defaultValue={setting.free_delivery}
                        // disabled
                        sx={{
                          mb: 2
                        }}
                        {...register("freeDelivery", {
                          required: 'Please fill all fields',
                          onChange: () => setEnableBtn(true),
                          pattern: {
                            message: 'Please fill all fields',
                          }
                        })}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                {enableBtn &&
                  <Box sx={{ mt: 2, display: "flex", alignItems: "center", justifyContent: "center" }}>
                    {/* <SecondaryButton
                    data-testId="back-btn"
                    title="Go Back"
                    onClick={() => navigate('/')}
                  /> */}

                    <PrimaryButton
                      data-testId="save-btn"
                      title={"Save changes"}
                      type="submit"
                    // loading={loading}
                    />
                  </Box>
                }
              </Typography>
            </Box>
          </Fragment>
        )}
      </Box>
    </Fragment>
  )
}

export default AppSettings