import React, { Fragment, useState } from "react";
import { Typography, Box, Grid, CardMedia, IconButton } from "@mui/material";
import colors from "../../../styles/colors";
import BreadCrumbs from "../../../components/BreadCrumbs/BreadCrumbs";
import { InputField } from "../../../components/Fields/TextField";
import { PrimaryButton } from "../../../components/Button/Button";
import {
  ErrorToaster,
  SuccessToaster,
} from "../../../components/Toaster/Toaster";
import PharmaciesServices from "../../../apis/Marketplace/Pharmacies/PharmaciesServices";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Close, CloudUpload } from "@mui/icons-material";
import AddressForm from "../../../components/Dialog/AddressForm";
import { Error } from "../../../components/UI/Error";
import { baseUrl } from "../../../../axios";

function CreateMPharmacy() {
  const [selectedImage, setSelectedImage] = useState("");
  const [imgPath, setImgPath] = useState("");
  const [addressFormDialog, setAddressFormDialog] = useState(false);
  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const [fileInputKey, setFileInputKey] = useState(Date.now());

  const createPharmacy = async (formData) => {
    setLoading(true);
    try {
      let obj = {
        name: formData.name,
        address: formData.address,
        phone: formData.phone,
        email: formData.email,
        image: imgPath,
        latitude: latitude,
        longitude: longitude,
        picture: imgPath,
      };
      const { data, message } = await PharmaciesServices.createPharmacy(obj);
      if (data === null) return;
      setTimeout(() => navigate("/marketplace/pharmacies"), 1000);
      SuccessToaster(message);
    } catch (error) {
      ErrorToaster(error);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const pharmacyPicture = async (image) => {
    const validImageTypes = ["image/png", "image/jpeg", "image/jpg"];
    if (!validImageTypes.includes(image?.type)) {
      ErrorToaster("Please upload a valid image file (PNG, JPEG, or JPG)");
    } else {
      try {
        let formData = new FormData();
        formData.append("imagePath", image);
        const { responseCode, data } = await PharmaciesServices.uploadImage(
          formData
        );
        if (responseCode === 200) {
          setImgPath(data.imgPath);
          setSelectedImage(data.imgPath);
          setFileInputKey(Date.now());
        }
      } catch (error) {
        ErrorToaster(error);
      }
    }
  };

  const handleAddress = (data) => {
    try {
      setValue("address", data?.address);
      setLatitude(data.latitude);
      setLongitude(data.longitude);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Fragment>
      <AddressForm
        open={addressFormDialog}
        onClose={() => setAddressFormDialog(false)}
        defaultData={{
          address: getValues("address"),
          latitude: latitude,
          longitude: longitude,
        }}
        save={(data) => {
          handleAddress(data);
          setAddressFormDialog(false);
        }}
      />
      <Box
        sx={{
          backgroundColor: colors.lightGray,
          p: 3,
          borderRadius: "12px",
          width: "calc(100vw - 370px)",
        }}
      >
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          sx={{ bgcolor: colors.white, p: 2, borderRadius: "12px" }}
        >
          <Grid item xs={6}>
            <Typography variant="body1" fontWeight="bold" color="initial">
              Create Pharmacy
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <BreadCrumbs currentPage={["Pharmacies", "Create Pharmacy"]} />
          </Grid>
        </Grid>
        <Box sx={{ bgcolor: colors.white, p: 3, borderRadius: "12px", mt: 2 }}>
          <Typography
            component={"form"}
            onSubmit={handleSubmit(createPharmacy)}
          >
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} md={6}>
                <InputField
                  label="Name"
                  register={register("name", {
                    required: "Please fill the field",
                  })}
                  error={errors?.name?.message}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Box
                  sx={{ cursor: "pointer" }}
                  onClick={() => setAddressFormDialog(true)}
                >
                  <InputField
                    label="Address"
                    placeholder="Address"
                    disabled={true}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={errors?.address?.message}
                    register={register("address", {
                      required: "Please enter address",
                    })}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <InputField
                  label="Phone"
                  register={register("phone", {
                    required: "Please fill the field",
                    pattern: {
                      value: /^[0-9]+$/,
                      message: "Please enter valid phone number",
                    },
                  })}
                  error={errors?.phone?.message}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <InputField
                  label="Email"
                  register={register("email", {
                    required: "Please fill the field",
                    pattern: {
                      value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                      message: "Please enter a valid email address",
                    },
                  })}
                  error={errors?.email?.message}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <input
                    // multiple
                    type="file"
                    key={fileInputKey}
                    accept="image/*"
                    style={{
                      opacity: "0",
                      outline: "none",
                      background: "white",
                      cursor: "inherit",
                      display: "none",
                    }}
                    id="uploadImage"
                    onInput={(event) => {
                      pharmacyPicture(event.target.files[0]);
                    }}
                    {...register("image", {
                      required: imgPath == "" ? "Please upload image" : false,
                    })}
                    error={errors?.image?.message}
                  />
                  <label
                    htmlFor="uploadImage"
                    style={{
                      display: selectedImage === "" ? "flex" : "none",
                      flexDirection: "column",
                      alignItems: "center",
                      marginTop: "10px",
                      border: errors?.image?.message
                        ? "1px solid #d32f2f"
                        : "1px solid #c4c4c4",
                      borderRadius: "6px",
                      padding: "20px",
                      cursor: "pointer",
                    }}
                  >
                    <Typography
                      sx={{
                        color: errors?.image?.message ? "#d32f2f" : colors.gray,
                      }}
                    >
                      {errors?.image ? errors?.image?.message : "Upload Image"}
                    </Typography>
                    <CloudUpload
                      sx={{
                        fontSize: "60px",
                        color: colors.primary,
                        cursor: "pointer",
                        "&:hover": {
                          color: colors.secondary,
                          transition: "0.3s ease-in-out",
                        },
                      }}
                    />
                  </label>
                  {selectedImage !== "" && (
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <Box sx={{ flexShrink: 0, position: "relative" }}>
                        <CardMedia
                          component={"img"}
                          image={baseUrl + selectedImage}
                          sx={{
                            height: "150px",
                            width: "150px",
                          }}
                        />
                        <IconButton
                          component="span"
                          disableRipple
                          sx={{
                            position: "absolute",
                            right: "-6px",
                            top: "-6px",
                            padding: "1px",
                          }}
                          onClick={() => setSelectedImage("")}
                        >
                          <Close sx={{ color: colors.danger }} />
                        </IconButton>
                      </Box>
                    </Box>
                  )}
                </Box>
              </Grid>
            </Grid>
            <Box
              sx={{
                mt: 2,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <PrimaryButton
                data-testId="save-btn"
                title={"Create"}
                type="submit"
                loading={loading}
              />
            </Box>
          </Typography>
        </Box>
      </Box>
    </Fragment>
  );
}

export default CreateMPharmacy;
