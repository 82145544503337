import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
//* Component Imports
import BreadCrumbs from "./../../components/BreadCrumbs/BreadCrumbs";
import { Layout } from "./../../layout/Layout";
import StaffServices from "../../apis/Staff/StaffServices";
import DataFilter from "./../../components/Filter/DataFilter";
import {
  ErrorToaster,
  SuccessToaster,
} from "./../../components/Toaster/Toaster";
import { Loading } from "./../../components/UI/Loader";
import { getDate } from "../../utils/DateFormate";
import { SecondaryButton } from "../../components/Button/Button";
import colors from "../../styles/colors";
//* MUI Imports
import { useTheme } from "@emotion/react";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import {
  Paper,
  Box,
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  IconButton,
  Switch,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
} from "@mui/material";
import { useForm } from "react-hook-form";
import useAuth from "../../hooks/useAuth";
import ResetPasswordDialog from "../../components/Dialog/ResetPasswordDialog";

//* Table Pagination ActionsComponent
function TablePaginationActions(props) {
  const theme = useTheme;

  const { count, page, rowsPerPage, onPageChange } = props;

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
    </Box>
  );
}

function AddStaffDialog({ open, handleClose }) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const handleAdd = async (formData) => {
    const obj = {
      name: formData.name,
      email: formData.email,
      phone: formData.phone,
    };
    try {
      const { message } = await StaffServices.addStaff(obj);
      SuccessToaster(message);
      reset();
      handleClose();
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Add Staff</DialogTitle>
      <Box component={"form"} onSubmit={handleSubmit(handleAdd)}>
        <DialogContent>
          <TextField
            autoComplete="off"
            margin="dense"
            label="Name"
            type="text"
            fullWidth
            variant="standard"
            {...register("name", {
              required: "Please enter name",
            })}
            error={errors?.name?.message}
          />
          <TextField
            autoComplete="off"
            margin="dense"
            label="Email"
            type="email"
            fullWidth
            variant="standard"
            {...register("email", {
              required: "Please enter email",
            })}
            error={errors?.email?.message}
          />
          <TextField
            autoComplete="off"
            margin="dense"
            label="Phone"
            type="text"
            fullWidth
            variant="standard"
            {...register("phone", {
              required: "Please enter phone",
            })}
            error={errors?.phone?.message}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit">Add</Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}

const tableHead = [
  "Registration Date",
  "Name",
  "Email",
  "Phone No.",
  "Activate",
];
const Staff = () => {
  const data = useAuth();
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  // *For Loader
  const [loader, setLoader] = useState(true);
  const [allStaff, setAllStaff] = useState([]);
  const [count, setCount] = useState("");
  const [checkName, setCheckName] = useState("");

  //* For Add Staff Dialog
  const [open, setOpen] = useState(false);

  // *For Status
  // const [isActive, setIsActive] = useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getAllStaff(newPage + 1, rowsPerPage);
  };

  const handleChangeRowsPerPage = (event) => {
    // setRowsPerPage(parseInt(event.target.value, 10));
    setRowsPerPage(Math.floor(event.target.value));
    setPage(0);
    getAllStaff(page + 1, event.target.value);
  };

  //* Name Filter function
  const sortByName = (stat, id, name) => {
    setCheckName(name);
    setRowsPerPage(10);
    setPage(0);
  };

  //* All Staff list
  const getAllStaff = async (newPage, rowsPerPage) => {
    setLoader(true);
    try {
      const { data, responseCode, status } = await StaffServices.getAllStaff(
        newPage ? newPage : 1,
        rowsPerPage ? rowsPerPage : ""
      );
      if (status === true && responseCode === 200) {
        setAllStaff(data.result);
        setCount(data.count);
      }
    } catch (error) {
      ErrorToaster(error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    getAllStaff();
  }, [open]);

  return (
    <Fragment>
      <AddStaffDialog open={open} handleClose={() => setOpen(false)} />
      <Box
        sx={{
          backgroundColor: colors.lightGray,
          p: 3,
          borderRadius: "12px",
          width: "calc(100vw - 370px)",
        }}
      >
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          sx={{
            bgcolor: colors.white,
            p: 2,
            borderRadius: "12px",
          }}
        >
          <Grid item xs={6}>
            <Typography variant="body1" fontWeight="bold" color="initial">
              Staff List
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <BreadCrumbs currentPage={["Staff"]} />
          </Grid>
        </Grid>
        {loader ? (
          <Box sx={{ width: 1, textAlign: "center", pt: 5 }}>
            <Loading />
          </Box>
        ) : (
          <Fragment>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                bgcolor: colors.white,
                p: 2,
                borderRadius: "12px",
                textAlign: "right",
                mt: 2,
              }}
            >
              <SecondaryButton
                title="Add Staff"
                onClick={() => setOpen(true)}
              />
            </Box>
            <Box>
              <DataFilter
                sortData={(stat, id, name) => sortByName(stat, id, name)}
                data={""}
                type={"user"}
              />
            </Box>
            <TableContainer
              component={Paper}
              sx={{
                "&::-webkit-scrollbar": {
                  bgcolor: colors.white,
                  height: "8px",
                  borderRadius: "10px",
                  cursor: "pointer",
                },
                "&::-webkit-scrollbar-thumb": {
                  bgcolor: colors.primary,
                  borderRadius: "10px",
                  cursor: "pointer",
                },
                "&.MuiPaper-root": {
                  borderRadius: "12px",
                  mt: 2,
                  boxShadow: 0,
                },
              }}
            >
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow sx={{ bgcolor: colors.lightGray }}>
                    {tableHead.map((item, index) => (
                      <TableCell key={index} sx={{ fontWeight: "bold" }}>
                        {item}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {allStaff.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row">
                        {getDate(row.createdAt)}
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2" color="initial" noWrap>
                          {row.name ? row.name : "-"}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2" color="initial" noWrap>
                          {row.email ?? "-"}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2" color="initial" noWrap>
                          {row.phone ? row.phone : "no phone"}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Switch size="small" checked={row.is_active} />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Table sx={{ marginTop: "12px" }}>
              <TablePagination
                sx={{
                  borderBottom: "none",
                  bgcolor: colors.white,
                  borderRadius: "12px",
                }}
                rowsPerPageOptions={[10, 20, 30]}
                colSpan={12}
                count={+count}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    "aria-label": "rows per page",
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </Table>
          </Fragment>
        )}
      </Box>
    </Fragment>
  );
};

export default Staff;
