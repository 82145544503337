
import { Box, CardMedia, CircularProgress } from '@mui/material';
import { Loader } from '../../assets/Images';

export const Loading = () => {
  return (
    <Box alignItems="center" sx={{ my: 2, }}>
      <CardMedia sx={{ width: 250, height: 'auto', m: 'auto' }}>
        <CircularProgress color="primary" />
      </CardMedia>
    </Box>
  );
}

export const CircleLoading = () => {
  return (
    <Box sx={{ display: 'block', position: 'relative', justifyContent: 'center', alignItems: 'center', minHeight: 380, height: 'calc(100vh - 210px)' }}>
      <Box sx={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%,-50%)' }}>
        <Loader />
      </Box>
    </Box>
  );
}