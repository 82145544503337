import { get, post, patch, deleted } from "../../index";
import MarketplaceVouchersRoutes from "./MarketplaceVouchers.Routes";

const MarketplaceVouchersServices = {
  getAllVouchers: async () => {
    const data = await get(MarketplaceVouchersRoutes.getAllVouchers);
    return data;
  },
  createVoucher: async (obj) => {
    const data = await post(MarketplaceVouchersRoutes.createVoucher, obj);
    return data;
  },
  updateVoucher: async (id, obj) => {
    const data = await patch(
      MarketplaceVouchersRoutes.updateVoucher + `?id=${id}`,
      obj
    );
    return data;
  },
  deletedVoucher: async (id) => {
    const data = await patch(
      MarketplaceVouchersRoutes.deleteVoucher + `?id=${id}`
    );
    return data;
  },
  applyVoucher: async (obj) => {
    const data = await post(MarketplaceVouchersRoutes.applyVoucher, obj);
    return data;
  },
};

export default MarketplaceVouchersServices;
