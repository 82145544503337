import React, { Fragment, useEffect } from "react";
import { Layout } from "./../../layout/Layout";
import { Typography, Box, Grid, Divider, InputAdornment } from "@mui/material";
import colors from "./../../styles/colors";
import BreadCrumbs from "./../../components/BreadCrumbs/BreadCrumbs";
import {
  InputField,
  OutlineInputField,
} from "./../../components/Fields/TextField";
import {
  AlternateEmail,
  FacebookTwoTone,
  LanguageTwoTone,
} from "@mui/icons-material";
import Images from "../../assets/Images";
import SettingServices from "./../../apis/Settings/SettingsServices";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import {
  ErrorToaster,
  SuccessToaster,
} from "./../../components/Toaster/Toaster";
import { Loading } from "../../components/UI/Loader";
import { SecondaryButton } from "../../components/Button/Button";
import { PrimaryButton } from "./../../components/Button/Button";

const ContactDetails = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [contact, setContact] = useState([]);
  const [loader, setLoader] = useState(true);
  const [enableBtn, setEnableBtn] = useState(false);
  const updateContactSettings = async (formData) => {
    try {
      let obj = {
        email: formData.email,
        facebook: formData.facebook,
        instagram: formData.insta,
        twiter: formData.tweet,
        website: formData.web,
        whatsapp: formData.wApp,
        youtube: formData.yTube,
        tiktok: formData.tikTok,
        linkedin: formData.linkedin,
        snapchat: formData.snap,
      };

      const { data } = await SettingServices.updateAppSettings(obj);
      if (data === null) return;
      SuccessToaster("Updated Successfully");
      contactSettings();
    } catch (error) {
      ErrorToaster(error);
    }
  };

  const contactSettings = async () => {
    try {
      const { data } = await SettingServices.appSettings();
      if (data === null) return;
      setContact(data.result);
    } catch (error) {
      ErrorToaster(error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    contactSettings();
  }, []);
  return (
    <Fragment>
      <Box
        sx={{
          backgroundColor: colors.lightGray,
          p: 3,
          borderRadius: "12px",
          width: "calc(100vw - 370px)",
        }}
      >
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          sx={{ bgcolor: colors.white, p: 2, borderRadius: "12px" }}
        >
          <Grid item xs={6}>
            <Typography variant="body1" fontWeight="bold" color="initial">
              Contact Settings
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <BreadCrumbs currentPage={["Contact Details"]} />
          </Grid>
        </Grid>
        {loader ? (
          <Box sx={{ width: 1, textAlign: "center", pt: 5 }}>
            <Loading />
          </Box>
        ) : (
          <Fragment>
            <Box
              sx={{ bgcolor: colors.white, p: 3, borderRadius: "12px", mt: 2 }}
            >
              <Typography
                component={"form"}
                onSubmit={handleSubmit(updateContactSettings)}
              >
                <Grid container spacing={2} alignItems="center">
                  {/* email */}
                  <Grid item xs={12} md={6}>
                    <OutlineInputField
                      defaultValue={contact.email}
                      label="Email"
                      endAdornment={
                        <InputAdornment position="end">
                          <AlternateEmail />
                        </InputAdornment>
                      }
                      register={register("email", {
                        required: "Please fill all fields",
                        onChange: () => setEnableBtn(true),
                        pattern: {
                          message: "Please fill all fields",
                        },
                      })}
                    />
                  </Grid>
                  {/* FaceBook */}
                  <Grid item xs={12} md={6}>
                    <OutlineInputField
                      defaultValue={contact.facebook}
                      label="FaceBook"
                      endAdornment={
                        <InputAdornment position="end">
                          <FacebookTwoTone sx={{ color: "#4267b2" }} />
                        </InputAdornment>
                      }
                      register={register("facebook", {
                        required: "Please fill all fields",
                        onChange: () => setEnableBtn(true),
                        pattern: {
                          message: "Please fill all fields",
                        },
                      })}
                    />
                  </Grid>
                  {/* Instagram */}
                  <Grid item xs={12} md={6}>
                    <OutlineInputField
                      defaultValue={contact.instagram}
                      label="Instagram"
                      endAdornment={
                        <InputAdornment position="end">
                          <img
                            src={Images.instagram}
                            alt="instagram"
                            style={{ width: "22px" }}
                          />
                        </InputAdornment>
                      }
                      register={register("insta", {
                        required: "Please fill all fields",
                        onChange: () => setEnableBtn(true),
                        pattern: {
                          message: "Please fill all fields",
                        },
                      })}
                    />
                  </Grid>
                  {/* Twitter */}
                  <Grid item xs={12} md={6}>
                    <OutlineInputField
                      defaultValue={contact.twiter}
                      label="Twitter"
                      endAdornment={
                        <InputAdornment position="end">
                          <img
                            src={Images.twit}
                            alt="instagram"
                            style={{ width: "22px" }}
                          />
                        </InputAdornment>
                      }
                      register={register("tweet", {
                        required: "Please fill all fields",
                        onChange: () => setEnableBtn(true),
                        pattern: {
                          message: "Please fill all fields",
                        },
                      })}
                    />
                  </Grid>
                  {/* Website */}
                  <Grid item xs={12} md={6}>
                    <OutlineInputField
                      defaultValue={contact.website}
                      label="Website"
                      endAdornment={
                        <InputAdornment position="end">
                          <LanguageTwoTone />
                        </InputAdornment>
                      }
                      register={register("web", {
                        required: "Please fill all fields",
                        onChange: () => setEnableBtn(true),
                        pattern: {
                          message: "Please fill all fields",
                        },
                      })}
                    />
                  </Grid>
                  {/* WhatsApp */}
                  <Grid item xs={12} md={6}>
                    <OutlineInputField
                      defaultValue={contact.whatsapp}
                      label="WhatsApp"
                      endAdornment={
                        <InputAdornment position="end">
                          <img
                            src={Images.wApp}
                            alt="instagram"
                            style={{ width: "22px" }}
                          />
                        </InputAdornment>
                      }
                      register={register("wApp", {
                        required: "Please fill all fields",
                        onChange: () => setEnableBtn(true),
                        pattern: {
                          value: /^[+]?[0-9]+(?:-?[0-9]+)*$/,
                          message: "Please enter valid number",
                        },
                      })}
                      error={errors?.wApp?.message}
                    />
                  </Grid>
                  {/* Youtube */}
                  <Grid item xs={12} md={6}>
                    <OutlineInputField
                      defaultValue={contact.youtube}
                      label="Youtube"
                      endAdornment={
                        <InputAdornment position="end">
                          <img
                            src={Images.youtube}
                            alt="instagram"
                            style={{ width: "22px" }}
                          />
                        </InputAdornment>
                      }
                      register={register("yTube", {
                        required: "Please fill all fields",
                        onChange: () => setEnableBtn(true),
                        pattern: {
                          message: "Please fill all fields",
                        },
                      })}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <OutlineInputField
                      defaultValue={contact.tiktok}
                      label="TikTok"
                      endAdornment={
                        <InputAdornment position="end">
                          <img
                            src={Images.tikTok}
                            alt="tikTok"
                            style={{ width: "22px" }}
                          />
                        </InputAdornment>
                      }
                      register={register("tikTok", {
                        required: "Please fill all fields",
                        onChange: () => setEnableBtn(true),
                        pattern: {
                          message: "Please fill all fields",
                        },
                      })}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <OutlineInputField
                      defaultValue={contact.snapchat}
                      label="SnapChat"
                      endAdornment={
                        <InputAdornment position="end">
                          <img
                            src={Images.snapChat}
                            alt="Snap"
                            style={{ width: "22px" }}
                          />
                        </InputAdornment>
                      }
                      register={register("snap", {
                        required: "Please fill all fields",
                        onChange: () => setEnableBtn(true),
                        pattern: {
                          message: "Please fill all fields",
                        },
                      })}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <OutlineInputField
                      defaultValue={contact.linkedin}
                      label="LinkedIn"
                      endAdornment={
                        <InputAdornment position="end">
                          <img
                            src={Images.linkedIn}
                            alt="linkedIn"
                            style={{ width: "22px" }}
                          />
                        </InputAdornment>
                      }
                      register={register("linkedin", {
                        required: "Please fill all fields",
                        onChange: () => setEnableBtn(true),
                        pattern: {
                          message: "Please fill all fields",
                        },
                      })}
                    />
                  </Grid>
                </Grid>
                {enableBtn && (
                  <Box
                    sx={{
                      mt: 2,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    {/* <SecondaryButton
                    data-testId="back-btn"
                    title="Go Back"
                    onClick={() => navigate('/')}
                  /> */}

                    <PrimaryButton
                      data-testId="save-btn"
                      title={"Save changes"}
                      type="submit"
                      // loading={loading}
                    />
                  </Box>
                )}
              </Typography>
            </Box>
          </Fragment>
        )}
      </Box>
    </Fragment>
  );
};

export default ContactDetails;
