import { Close } from '@mui/icons-material';
import { Box, Dialog, DialogTitle, IconButton, Grid, Typography, DialogContent, DialogActions, Button, FormControlLabel, Checkbox } from '@mui/material';
import colors from '../../styles/colors';
import { useEffect, useState } from 'react';
import { ErrorToaster } from '../Toaster/Toaster';


function AllocationDialog({ open, onClose, data, onSave }) {

  // *For Check Box
  const [isChecked, setIsChecked] = useState([]);

  // *For Checkbox Handle
  const handleChecked = (value) => {
    const currentIndex = isChecked.indexOf(value);
    const newChecked = [...isChecked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setIsChecked(newChecked);
  };

  const selectDefault = (data) => {
    try {
      let array = []
      data.map(element => {
        if (element.selected === true) {
          array.push(element._id)
        }
      })
      setIsChecked(array)
    } catch (error) {
      ErrorToaster(error)
    }
  }

  const handleSave = () => {
    try {
      onSave(isChecked)
    } catch (error) {
      console.log('file: AllocationDialog.js:45 => handleSave => error:', error)
    }
  }

  useEffect(() => {
    if (data) {
      selectDefault(data)
    }
  }, [data]);

  return (
    <Dialog
      data-testId="dialog-box"
      maxWidth="xs"
      open={open}
      sx={{ '& .MuiDialog-paper': { width: '90%', height: "auto", borderRadius: 2, py: { xs: 2, md: 4 }, px: { xs: 3, md: 6 } } }}
    >
      <IconButton data-testId="close-dialog" color="primary" onClick={() => onClose()} sx={{ position: 'absolute', right: 13, top: 13 }}>
        <Close />
      </IconButton>
      <DialogTitle sx={{ textAlign: "center", fontSize: '18px', fontWeight: 700 }}>Pharmacies</DialogTitle>
      <DialogContent sx={{ pt: 1, px: 0 }}>
        <Grid container spacing={2}>
          {data?.map((item, index) => (
            <Grid key={index} item xs={12} sm={6}>
              <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', cursor: 'pointer' }} onClick={() => { handleChecked(item._id) }}>
                <Checkbox
                  checked={isChecked.indexOf(item._id) !== -1}
                />
                <Typography variant="body1" color="initial">{item.name}</Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </DialogContent>
      <DialogActions sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
        <Button
          onClick={() => handleSave()}
          variant='contained'
          sx={{
            textTransform: 'capitalize',
            minWidth: '100px',
            boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
            fontSize: 12, fontWeight: 'bold',
            backgroundColor: colors.accepted,
            "&:hover": {
              backgroundColor: colors.accepted,
            }
          }}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AllocationDialog