import { Close } from '@mui/icons-material';
import { Box, Dialog, DialogTitle, IconButton, Grid, Typography, Link } from '@mui/material';
import { baseUrl } from '../../../axios';
import { Swiper, SwiperSlide } from "swiper/react";
//* Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
//* import required modules
import { Navigation } from "swiper";
import { Loading } from './../UI/Loader';
import { SecondaryButton } from '../Button/Button';

function MedicineDialog({ open, onClose, image, download }) {

  const downloadFun = (url, name) => {
    download(url, name)
  }
  return (
    <Dialog
      // data-testId="dialog-box"
      maxWidth="xs"
      open={open}
      sx={{ '& .MuiDialog-paper': { width: '80%', height: "500px", borderRadius: 2, py: { xs: 2, md: 4 }, px: { xs: 3, md: 6 } } }}
    >
      <IconButton data-testId="close-dialog" color="primary" onClick={() => onClose()} sx={{ position: 'absolute', right: 13, top: 13 }}>
        <Close />
      </IconButton>
      <DialogTitle sx={{ textAlign: "center", fontSize: '18px', fontWeight: 700 }}>{"Medicine"}</DialogTitle>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} >
          {!image ?
            <Box sx={{ width: 1, textAlign: "center", pt: 5 }}>
              <Loading />
            </Box>
            :
            <Swiper navigation={true} modules={[Navigation]} >
              <SwiperSlide style={{ display: "flex", justifyContent: "center" }}>
                <img src={baseUrl + image} alt="Medicine" style={{ objectFit: "cover", width: "100%" }} />
              </SwiperSlide>
            </Swiper>
          }
        </Grid>
        <Grid xs={12} pt={4} sx={{ display: 'flex', justifyContent: 'center' }}>
          <SecondaryButton title="Download Image"
            onClick={() => downloadFun(baseUrl + image, "medicine")}
          />
          <Link sx={{ cursor: "pointer" }}></Link>
        </Grid>
      </Grid>
    </Dialog>
  )
}

export default MedicineDialog