import { Close } from "@mui/icons-material";
import {
  Box,
  Dialog,
  DialogTitle,
  IconButton,
  Grid,
  Typography,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import colors from "../../styles/colors";
import MOrderServices from "../../apis/Marketplace/MarketplaceOrders/MOrderServices";
import {
  ErrorToaster,
  SuccessToaster,
} from "./../../components/Toaster/Toaster";

function OrderUpdateDialog({ open, onClose, id, title, btn }) {
  const handleDelivery = async () => {
    try {
      const obj = {
        id: id,
        status: "Delivery",
      };
      const { status, message, responseCode } =
        await MOrderServices.updateOrderStatus(obj);
      if (status === true && responseCode === 200) {
        SuccessToaster(message);
      }
    } catch (error) {
      ErrorToaster(error);
    }
  };

  const handleProcessing = async () => {
    try {
      const obj = {
        id: id,
        status: "Processing",
      };
      const { status, message, responseCode } =
        await MOrderServices.updateOrderStatus(obj);
      if (status === true && responseCode === 200) {
        SuccessToaster(message);
      }
    } catch (error) {
      ErrorToaster(error);
    }
  };

  const handleCompleted = async () => {
    try {
      const obj = {
        id: id,
        status: "Completed",
      };
      const { status, message, responseCode } =
        await MOrderServices.updateOrderStatus(obj);
      if (status === true && responseCode === 200) {
        SuccessToaster(message);
      }
    } catch (error) {
      ErrorToaster(error);
    }
  };

  return (
    <Dialog
      data-testId="dialog-box"
      maxWidth="xs"
      open={open}
      sx={{
        "& .MuiDialog-paper": {
          width: "80%",
          height: "auto",
          borderRadius: 2,
          py: { xs: 2, md: 4 },
          px: { xs: 3, md: 6 },
        },
      }}
    >
      <IconButton
        data-testId="close-dialog"
        color="primary"
        onClick={() => onClose()}
        sx={{ position: "absolute", right: 13, top: 13 }}
      >
        <Close />
      </IconButton>
      <DialogContent sx={{ pt: 1 }}>
        <Box display="flex" justifyContent="center" p={1}>
          <Typography variant="h5" fontWeight="bold">
            {title}
          </Typography>
        </Box>
        <Box display="flex" justifyContent="center">
          You won't be able to revert this!
        </Box>
      </DialogContent>
      <DialogActions sx={{ display: "flex", justifyContent: "center", p: 2 }}>
        <Button
          onClick={() => {
            handleProcessing();
            onClose();
          }}
          variant="contained"
          sx={{
            textTransform: "capitalize",
            minWidth: "100px",
            boxShadow:
              "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
            fontSize: 12,
            fontWeight: "bold",
            backgroundColor: colors.pending, // btn ? colors.accepted : colors.danger, color: colors.opalBg,
            "&:hover": {
              backgroundColor: colors.mercury, // colors.danger,
              color: colors.black,
            },
          }}
        >
          {btn ? btn : "Under Processing"}
        </Button>
        <Button
          onClick={() => {
            handleDelivery();
            onClose();
          }}
          variant="contained"
          sx={{
            textTransform: "capitalize",
            minWidth: "100px",
            boxShadow:
              "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
            fontSize: 12,
            fontWeight: "bold",
            backgroundColor: colors.accepted, //btn ? colors.accepted : colors.danger, color: colors.opalBg,
            "&:hover": {
              backgroundColor: colors.mercury, //btn ? colors.accepted : colors.danger,
              color: colors.black,
            },
          }}
        >
          {btn ? btn : "Out for delivery"}
        </Button>
        <Button
          onClick={() => {
            handleCompleted();
            onClose();
          }}
          variant="contained"
          sx={{
            textTransform: "capitalize",
            minWidth: "100px",
            boxShadow:
              "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
            fontSize: 12,
            fontWeight: "bold",
            backgroundColor: colors.completed, //btn ? colors.accepted : colors.danger, color: colors.opalBg,
            "&:hover": {
              backgroundColor: colors.mercury, //btn ? colors.accepted : colors.danger,
              color: colors.black,
            },
          }}
        >
          {btn ? btn : "Completed"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default OrderUpdateDialog;
