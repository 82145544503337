import React, { useEffect, useState, Fragment } from 'react'
import { Layout } from '../../layout/Layout'
import colors from './../../styles/colors';
import BreadCrumbs from './../../components/BreadCrumbs/BreadCrumbs';
import TextEditor from '../../components/TextEditor/TextEditor';
import ContentServices from './../../apis/Content/ContentServices';
import { ErrorToaster, SuccessToaster } from './../../components/Toaster/Toaster';
import { Loading } from './../../components/UI/Loader';
import { PrimaryButton } from './../../components/Button/Button';
import { Box, Grid, Typography, Divider } from '@mui/material';

const Conditions = () => {
  // *For Loader
  const [termsLoader, setTermsLoader] = useState(true);
  const [privacyLoader, setPrivacyLoader] = useState(true);

  const [content, setContent] = useState('')

  const [contentAr, setContentAr] = useState('')
  const [privacyContent, setPrivacyContent] = useState('')
  const [privacyContentAr, setPrivacyContentAr] = useState('')
  const [termsId, setTermsId] = useState('')
  const [privacyId, setPrivacyId] = useState('')

  const getTextArValue = (value) => {
    setContentAr(value)
  }
  const getTextValue = (value) => {
    setContent(value)
  }
  const getPrivacyTextValue = (value) => {
    setPrivacyContent(value)
  }
  const getPrivacyTextArValue = (value) => {
    setPrivacyContentAr(value)
  }
  //* Api For Get Privacy And Terms And Condition Content
  const termsContent = async () => {
    try {
      const { data } = await ContentServices.termsContent()
      if (data === null) return
      setContent(data.result.content)
      setContentAr(data.result.content_ar)
      setTermsId(data.result._id)
    }
    catch (error) {
      ErrorToaster(error)
    }
    finally {
      setTermsLoader(false)
    }
  }
  const privacy = async () => {
    try {
      const { data } = await ContentServices.privacy()
      if (data === null) return
      setPrivacyContent(data.result.content)
      setPrivacyContentAr(data.result.content_ar)
      setPrivacyId(data.result._id)
    }
    catch (error) {
      ErrorToaster(error)
    }
    finally {
      setPrivacyLoader(false)
    }
  }

  //* For Submit New Content Or Edit Content
  const privacySubmit = async () => {
    try {
      const obj = {
        page_name: "Privacy Policy",
        content: privacyContent,
        content_ar: privacyContentAr,
      }
      const { data, message } = await ContentServices.createContent(privacyId, obj);
      if (data === null) return
      SuccessToaster(message)
    }
    catch (error) {
      ErrorToaster(error)
    }
  }
  const contentSubmit = async () => {
    try {
      const obj = {
        page_name: "Terms and conditions",
        content: content,
        content_ar: contentAr,
      }

      const { data, message } = await ContentServices.createContent(termsId, obj);
      if (data === null) return
      SuccessToaster(message)
    }
    catch (error) {
      ErrorToaster(error)
    }
  }

  useEffect(() => {
    privacy()
    termsContent()
  }, [])

  return (
    <Fragment>
      <Box sx={{ backgroundColor: colors.lightGray, p: 3, borderRadius: "12px", width: "calc(100vw - 370px)" }}>
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          sx={{ bgcolor: colors.white, p: 2, borderRadius: "12px", }}
        >
          <Grid item xs={6}>
            <Typography variant="body1" fontWeight="bold" color="initial">Terms & Conditions</Typography>
          </Grid>
          <Grid item xs={6} sx={{ display: "flex", justifyContent: "flex-end" }}>
            <BreadCrumbs currentPage={['Terms & Conditions']} />
          </Grid>
        </Grid>
        {termsLoader ? (
          <Box sx={{ width: 1, textAlign: "center", pt: 5 }}>
            <Loading />
          </Box>
        ) : (
          <Fragment>
            <Box sx={{ bgcolor: colors.white, p: 3, borderRadius: "12px", mt: 2 }}>
              <Typography variant="body1" fontWeight="bold" color="initial">Terms and Conditions</Typography>
              <Divider sx={{ my: 2 }} />
              <Grid container spacing={2}>
                <Grid item xs={12} display="flex" justifyContent="center">
                  <Box>
                    <Typography>Content</Typography>
                    <TextEditor getValue={getTextValue} description={content} />
                  </Box>
                </Grid>
                <Grid item xs={12} display="flex" justifyContent="center">
                  <Box>
                    <Typography>Arabic Content</Typography>
                    <TextEditor getValue={getTextArValue} description={contentAr} type={true} />
                  </Box>
                </Grid>
              </Grid>
              <Box sx={{ mt: 2, display: "flex", alignItems: "center", justifyContent: "center" }}>
                <PrimaryButton
                  data-testId="save-btn"
                  title={"Save"}
                  onClick={contentSubmit}
                // loading={loading}
                />
              </Box>
            </Box>
          </Fragment>)}
        {privacyLoader ? (
          <Box sx={{ width: 1, textAlign: "center", pt: 5 }}>
            <Loading />
          </Box>
        ) : (
          <Box sx={{ bgcolor: colors.white, p: 3, borderRadius: "12px", mt: 2 }}>
            <Typography variant="body1" fontWeight="bold" color="initial">Privacy Policy</Typography>
            <Divider sx={{ my: 2 }} />
            <Grid container spacing={2}>
              <Grid item xs={12} display="flex" justifyContent="center">
                <Box>
                  <Typography>Content</Typography>
                  <TextEditor getValue={getPrivacyTextValue} description={privacyContent} />
                </Box>
              </Grid>
              <Grid item xs={12} display="flex" justifyContent="center">
                <Box>
                  <Typography>Arabic Content</Typography>
                  <TextEditor getValue={getPrivacyTextArValue} description={privacyContentAr} type={true} />
                </Box>
              </Grid>
            </Grid>
            <Box sx={{ mt: 2, display: "flex", alignItems: "center", justifyContent: "center" }}>
              <PrimaryButton
                data-testId="save-btn"
                title={"Save"}
                onClick={privacySubmit}
              // loading={loading}
              />
            </Box>
          </Box>
        )}

      </Box>
    </Fragment>
  )
}

export default Conditions