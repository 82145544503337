import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBSAh2iqYaVDNIHLXSGl7iE-Ed1fTPdNOw",
  authDomain: "erx-hub-3b89b.firebaseapp.com",
  projectId: "erx-hub-3b89b",
  storageBucket: "erx-hub-3b89b.appspot.com",
  messagingSenderId: "847731627857",
  appId: "1:847731627857:web:6ab722f2f61d007381a570",
  measurementId: "G-NKGZ3MK2RR"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);


export const getUserToken = () => {
  return getToken(messaging, { vapidKey: 'BFalb4dwX6HVjaJwP0pKrUWh94B6SU2UwEhpziLtIyaK-vhUTX53u1rqR6ZfaYMIUO3T4OsI1gV6EOjh38T1u1k' })
    .then((currentToken) => {
      if (currentToken) {
        return currentToken
      }
      else {
        console.log("no auth")
      }
    })
    .catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
    });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      // console.log("🚀 ~ file: firebase.js:40 ~ onMessage ~ payload", payload)
      resolve(payload);
    });
  });
