const MOrderRoutes = {
  getAllOrders: "/orders/marketplace",
  quoteOrders: "/orders/quote",
  getOrderDetails: "/orders/orderbyid",
  updateOrderStatus: "/orders/status",
  getOrderStatus: "/status",
  completeOrder: "/orders/delivered",
  orderNotification: "orders/sendNotifications",
};

export default MOrderRoutes;
