import { ListAlt, Storefront, Inventory } from "@mui/icons-material";
import colors from "./styles/colors";
const styles = {
  iconSize: {
    p: 0.2,
    fontSize: "22px",
    color: colors.secondary,
  },
};

const SNavigation = [
  {
    label: "Orders",
    icon: <ListAlt sx={styles.iconSize} />,
    path: "/orders",
    subPath: "/order/:id",
  },
  {
    label: "Marketplace",
    isOpen: false,
    icon: <Storefront sx={styles.iconSize} />,
    subMenu: [
      {
        label: "Orders",
        icon: <Inventory sx={styles.iconSize} />,
        path: "/marketplace/orders",
      },
    ],
  },
];

export default SNavigation;
