import { get, post, patch, deleted } from '../index'
import PharmacyRoutes from './Pharmacy.Routes';

const PharmacyServices = {
  createPharmacy: async (obj) => {
    const data = await post(PharmacyRoutes.createPharmacy, obj)
    return data
  },
  getPharmacy: async (page, rowsPerPage) => {
    const data = await get(PharmacyRoutes.getPharmacy + `?perPage=${rowsPerPage}&page=${page}`)
    return data
  },
  updatePharmacy: async (obj) => {
    const data = await patch(PharmacyRoutes.updatePharmacy, obj)
    return data
  },
  deletePharmacy: async (id) => {
    const data = await deleted(PharmacyRoutes.deletePharmacy + `?pharmacy_id=${id}`)
    return data
  },
  getAllocatedPharmacy: async (id, page, rowsPerPage) => {
    const data = await get(PharmacyRoutes.getAllocatedPharmacy + `?order_id=${id}&perPage=${rowsPerPage}&page=${page}`)
    return data
  },
  getPharmacyList: async (id) => {
    const data = await get(PharmacyRoutes.getPharmacyList + `?order_id=${id}`)
    return data
  },
  allocateOrder: async (obj) => {
    const data = await post(PharmacyRoutes.allocateOrder, obj)
    return data
  },
  getAllocationDetail: async (id) => {
    const data = await get(PharmacyRoutes.getAllocationDetail + `?id=${id}`)
    return data
  },
  SendMessage: async (obj) => {
    const data = await post(PharmacyRoutes.SendMessage, obj)
    return data
  },
}

export default PharmacyServices