import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import BreadCrumbs from "./../../../components/BreadCrumbs/BreadCrumbs";
// import { Layout } from "../../../layout/Layout";
import MOrderServices from "../../../apis/Marketplace/MarketplaceOrders/MOrderServices";
//* Required Components
import {
  ErrorToaster,
  SuccessToaster,
} from "./../../../components/Toaster/Toaster";
import OrderUpdateDialog from "../../../components/Dialog/OrderUpdateDialog";
import colors from "./../../../styles/colors";
import { getTimeDate } from "./../../../utils/DateFormate";
import { Loading } from "./../../../components/UI/Loader";
//* MUI Imports
import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import {
  Recommend,
  RequestQuote,
  Done,
  Visibility,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  NotificationImportant,
  FilterAltTwoTone,
  Search,
  DynamicFeed,
} from "@mui/icons-material";
import {
  Paper,
  Box,
  Grid,
  Typography,
  tooltipClasses,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  IconButton,
  Button,
  Collapse,
  FormControl,
  InputAdornment,
  TextField,
  Autocomplete,
} from "@mui/material";
//* Firebase import
import { onMessageListener } from "./../../../firebase/firebase";
// import { Box,   Grid, IconButton,   Typography } from '@mui/material'
import SelectField from "./../../../components/Fields/SelectField";
// import { InputField } from "../../../components/Fields/TextField";

function DataFilter({ data, sortData, page, rows }) {
  const [filterCollapse, setFilterCollapse] = useState(true);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const [orderId, setOrderId] = useState("");
  const [name, setName] = useState("");
  const [orderIds, setOrderIds] = useState([]);

  const [selectedName, setSelectedName] = useState("");

  const filterData = () => {
    sortData(fromDate, toDate, selectedId, orderId, rows, page, name);
  };

  const getOrderIds = async () => {
    try {
      const { data } = await MOrderServices.getAllOrders(
        "",
        "",
        "",
        "",
        rows,
        page + 1,
        ""
      );
      const ids = data?.result?.map((item) => item?.order_id);
      setOrderIds(ids);
    } catch (err) {
      console.log(err);
    }
  };

  const clearData = () => {
    sortData("");
    setSelectedName("");
    setFilterCollapse(!filterCollapse);
  };

  useEffect(() => {
    getOrderIds();
  }, []);

  return (
    <Box sx={{ bgcolor: colors.white, p: 2, borderRadius: "12px", mt: 2 }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="body1" fontWeight="bold" color="initial">
          Filters
        </Typography>
        <IconButton
          onClick={() => setFilterCollapse(!filterCollapse)}
          color="primary"
          sx={{ bgcolor: colors.primaryLight }}
        >
          <FilterAltTwoTone />
        </IconButton>
      </Box>

      <Collapse in={filterCollapse}>
        <Grid container spacing={2} justifyContent="space-between">
          <Fragment>
            <Grid item md={6}>
              <FormControl fullWidth>
                <TextField
                  label="User Name"
                  size={"small"}
                  variant="outlined"
                  onChange={(e) => setName(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      sortData(
                        fromDate,
                        toDate,
                        selectedId,
                        orderId,
                        rows,
                        page,
                        e.target.value
                      );
                    }
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item md={6}>
              <SelectField
                setState={(item, name) => {
                  setSelectedId(item);
                  setSelectedName(name);
                }}
                stateValue={selectedName}
                data={data}
                label={"Select Status"}
              />
            </Grid>
            <Grid item md={6}>
              <FormControl fullWidth>
                {/* <TextField
                  label="Order Id"
                  size={"small"}
                  variant="outlined"
                  onChange={(e) => setOrderId(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      sortData(
                        fromDate,
                        toDate,
                        selectedId,
                        e.target.value,
                        rows,
                        page,
                        name
                      );
                    }
                  }}
                /> */}
                <Autocomplete
                  fullWidth
                  disablePortal
                  label="Order Id"
                  size="small"
                  options={orderIds}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      sortData(
                        selectedId,
                        e.target.value,
                        name,
                        fromDate,
                        toDate,
                        page,
                        rows
                      );
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      {...params}
                      label="Order Id"
                      onSelect={(e) => setOrderId(e.target.value)}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item md={6} container alignItems="center">
              <Grid container spacing={1}>
                <Grid item md={6}>
                  <FormControl size="small" fullWidth variant="outlined">
                    <TextField
                      label="From Date"
                      size={"small"}
                      variant="outlined"
                      type="date"
                      InputLabelProps={{ shrink: true, required: true }}
                      onChange={(e) => setFromDate(e.target.value)}
                    />
                  </FormControl>
                </Grid>
                <Grid item md={6}>
                  <FormControl size="small" fullWidth variant="outlined">
                    <TextField
                      label="To Date"
                      size={"small"}
                      variant="outlined"
                      type="date"
                      InputLabelProps={{ shrink: true, required: true }}
                      onChange={(e) => setToDate(e.target.value)}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Fragment>
          <Grid item xs={12} display="flex" justifyContent="flex-end">
            <Button
              onClick={clearData}
              variant="outlined"
              sx={{
                mx: 1,
                textTransform: "capitalize",
                minWidth: "100px",
                boxShadow:
                  "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
              }}
            >
              Clear
            </Button>
            <Button
              onClick={filterData}
              variant="contained"
              sx={{
                textTransform: "capitalize",
                minWidth: "100px",
                boxShadow:
                  "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
              }}
            >
              Search
            </Button>
          </Grid>
        </Grid>
      </Collapse>
    </Box>
  );
}

//* Table Pagination ActionsComponent
function TablePaginationActions(props) {
  const theme = useTheme;

  const { count, page, rowsPerPage, onPageChange } = props;

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
    </Box>
  );
}

//* custom tooltip
const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 200,
    textAlign: "center",
    background: "#0D253C",
    "&>.MuiTooltip-arrow": {
      "&:before": {
        color: "#0D253C",
      },
    },
  },
});

const tableHead = [
  "Order Id",
  "Order By",
  "Placed On",
  "Payment Method",
  "Status",
  "Action",
  "Detail",
];
const MOrder = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [RowsPerPage, setRowsPerPage] = useState(10);
  const [orderId, setOrderId] = useState("");
  const [count, setCount] = useState("");
  const [rowsData, setRowsData] = useState([]);
  const [quoteDialog, setQuoteDialog] = useState(false);
  const [orderUpdateDialog, setOrderUpdateDialog] = useState(false);
  const [checkStatus, setCheckStatus] = useState("");
  const [checkName, setCheckName] = useState("");
  const [checkOrderId, setCheckOrderId] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  // *For Loader
  const [loader, setLoader] = useState(true);
  const role = localStorage.getItem("role");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    allOrders(
      fromDate ? fromDate : "",
      toDate ? toDate : "",
      checkStatus ? checkStatus : "",
      checkOrderId ? checkOrderId : "",
      RowsPerPage,
      newPage + 1,
      checkName ? checkName : ""
    );
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(Math.floor(event.target.value));
    setPage(0);
    allOrders(
      fromDate ? fromDate : "",
      toDate ? toDate : "",
      checkStatus ? checkStatus : "",
      checkOrderId ? checkOrderId : "",
      event.target.value,
      1,
      checkName ? checkName : ""
    );
  };

  const [status, setStatus] = useState([]);

  // *For Get Status ID's
  const sortByStatus = (fromDate, toDate, stat, id, rows, page, name) => {
    setCheckStatus(stat);
    setCheckOrderId(id);
    setCheckName(name);
    setFromDate(fromDate);
    setToDate(toDate);
    setRowsPerPage(10);
    setPage(0);
    allOrders(
      fromDate ? fromDate : "",
      toDate ? toDate : "",
      stat ? stat : "",
      id ? id : "",
      rows,
      page,
      name ? name : ""
    );
  };

  const orderStatus = async () => {
    try {
      const { data } = await MOrderServices.getOrderStatus();
      if (data === null) return;
      setStatus(data.result);
    } catch (error) {
      // ErrorToaster(error)
    }
  };

  //* All Orders API Call
  const allOrders = async (from, to, stat, id, rowsPerPage, newPage, name) => {
    setLoader(true);
    try {
      const { data } = await MOrderServices.getAllOrders(
        from ? from : "",
        to ? to : "",
        stat ? stat : "",
        id ? id : "",
        rowsPerPage ? rowsPerPage : "",
        newPage ? newPage : 1,
        name ? name : ""
      );
      if (data === null) return;
      setRowsData(data.result);
      setCount(data.count);
    } catch (error) {
      ErrorToaster(error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    allOrders();
    orderStatus();
  }, []);

  //* FireBase Message Listner for reload page
  onMessageListener()
    .then((payload) => {
      allOrders();
      orderStatus();
    })
    .catch((err) => console.log("failed: ", err));

  return (
    <Fragment>
      <OrderUpdateDialog
        open={orderUpdateDialog}
        onClose={() => setOrderUpdateDialog(!orderUpdateDialog)}
        id={orderId}
        title={"Update Order Status"}
      />
      <Box
        sx={{
          backgroundColor: colors.lightGray,
          p: 3,
          borderRadius: "12px",
          width: "calc(100vw - 370px)",
        }}
      >
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          sx={{
            bgcolor: colors.white,
            p: 2,
            borderRadius: "12px",
          }}
        >
          <Grid item xs={6}>
            <Typography variant="body1" fontWeight="bold" color="initial">
              Marketplace Orders List
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <BreadCrumbs currentPage={["Marketplace Orders"]} />
          </Grid>
        </Grid>
        {loader ? (
          <Box sx={{ width: 1, textAlign: "center", pt: 5 }}>
            <Loading />
          </Box>
        ) : (
          <Fragment>
            <Box>
              {/* <DataFilter
                sortData={(stat, id, name, type, fromDate, toDate) =>
                  sortByStatus(stat, id, name, type, fromDate, toDate)
                }
                data={status?.map((item) => {
                  return item;
                })}
                type={"order"}
              /> */}
              <DataFilter
                sortData={(stat, id, name, fromDate, toDate, page, rows) =>
                  sortByStatus(stat, id, name, fromDate, toDate, page, rows)
                }
                data={status?.map((item) => {
                  return item;
                })}
                page={page}
                rows={RowsPerPage}
              />
            </Box>
            <TableContainer
              component={Paper}
              sx={{
                "&::-webkit-scrollbar": {
                  bgcolor: colors.white,
                  height: "8px",
                  borderRadius: "10px",
                  cursor: "pointer",
                },
                "&::-webkit-scrollbar-thumb": {
                  bgcolor: colors.primary,
                  borderRadius: "10px",
                  cursor: "pointer",
                },
                "&.MuiPaper-root": {
                  borderRadius: "12px",
                  mt: 2,
                  boxShadow: 0,
                },
              }}
            >
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow sx={{ bgcolor: colors.lightGray }}>
                    {tableHead.map((item, index) => (
                      <TableCell key={index} sx={{ fontWeight: "bold" }}>
                        {item}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rowsData.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row">
                        {row.order_id}
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">
                          <b>Name: </b>
                          {row?.order_by ?? "--"}
                        </Typography>
                        <Typography variant="body2" noWrap>
                          <b>Email: </b>
                          {row.deleted_email
                            ? row.deleted_email
                            : row.order_by_email}
                        </Typography>
                        <Typography variant="body2">
                          <b>Phone: </b>
                          {row.deleted_phone
                            ? row.deleted_phone
                            : row.user_phone}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2" color="initial" noWrap>
                          {getTimeDate(row.date_time)}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>{row.payment.payment_method}</Typography>
                      </TableCell>
                      <TableCell>
                        <CustomTooltip title={row.status.ar} arrow>
                          <Typography
                            variant="body2"
                            color="initial"
                            noWrap
                            sx={{
                              cursor: "default",
                              maxWidth: 170,
                              color:
                                row.status.name === "Requested"
                                  ? colors.notification
                                  : row.status.name === "Quoted"
                                  ? colors.black
                                  : row.status.name === "Accepted"
                                  ? colors.secondary
                                  : row.status.name === "Confirmed"
                                  ? colors.primary
                                  : row.status.name === "Completed"
                                  ? colors.completed
                                  : colors.danger,
                            }}
                          >
                            {row.status.name}
                          </Typography>
                        </CustomTooltip>
                      </TableCell>
                      <TableCell>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Tooltip title="Update">
                            <IconButton
                              onClick={() => {
                                setOrderUpdateDialog(true);
                                setOrderId(row?._id);
                              }}
                            >
                              <DynamicFeed
                                sx={{
                                  color: colors.secondary,
                                  fontSize: "20px",
                                }}
                              />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Tooltip title="View">
                          <IconButton
                            onClick={() =>
                              navigate(`/marketplace/order/${row._id}`)
                            }
                          >
                            <Visibility sx={{ color: colors.completed }} />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Table sx={{ marginTop: "12px" }}>
              <TablePagination
                sx={{
                  borderBottom: "none",
                  bgcolor: colors.white,
                  borderRadius: "12px",
                }}
                rowsPerPageOptions={[10, 20, 30]}
                colSpan={12}
                count={count === undefined ? 0 : +count}
                rowsPerPage={RowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    "aria-label": "rows per page",
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </Table>
          </Fragment>
        )}
      </Box>
    </Fragment>
  );
};

export default MOrder;
