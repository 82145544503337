import React, { Fragment, useEffect, useState } from 'react'
import colors from '../../styles/colors';
import BreadCrumbs from './../../components/BreadCrumbs/BreadCrumbs';
import { Layout } from './../../layout/Layout';
import UsersServices from '../../apis/Users/UsersServices';
import { ErrorToaster } from './../../components/Toaster/Toaster';
import { Loading } from './../../components/UI/Loader';
import { getDate } from '../../utils/DateFormate';
import UserIdCardDialog from '../../components/Dialog/UserIdCardDialog';
import DataFilter from '../../components/Filter/DataFilter';
import { useTheme } from '@emotion/react'
import { KeyboardArrowLeft, KeyboardArrowRight, Visibility } from '@mui/icons-material'
import { Paper, Box, Grid, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Tooltip, IconButton, } from '@mui/material';

// Table Pagination ActionsComponent
function TablePaginationActions(props) {

  const theme = useTheme

  const { count, page, rowsPerPage, onPageChange } = props;

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };



  useEffect(() => {
    window.scrollTo({ top: 0 })
  }, [])


  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
    </Box>
  );
}


const tableHead = ['Registration Date', "Deleted On", 'Name', 'Email', 'Phone No.', 'Emirates ID view', 'Insurance Card View']

const DeletedUsers = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  // *For Loader
  const [loader, setLoader] = useState(true);
  const [idCardModal, setIdCardModal] = useState(false)
  const [idImg, setIdImg] = useState([])
  const [title, setTitle] = useState('')
  const [deletedUsers, setDeletedUsers] = useState([])
  const [count, setCount] = useState("")
  const [checkName, setCheckName] = useState("")

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getDeletedUsers(checkName ? checkName : "", newPage + 1, rowsPerPage)
  };

  const handleChangeRowsPerPage = (event) => {
    // setRowsPerPage(parseInt(event.target.value, 10));
    setRowsPerPage(Math.floor(event.target.value))
    setPage(0);
    getDeletedUsers(checkName ? checkName : "", page + 1, event.target.value)
  };

  //* For Filter function
  const sortByName = (stat, id, name) => {
    setCheckName(name)
    setRowsPerPage(10)
    setPage(0)
    getDeletedUsers(name ? name : "")
  }

  //* All Deleted Users Api
  const getDeletedUsers = async (name, newPage, rowsPerPage) => {
    setLoader(true)
    try {
      const { data, responseCode, status } = await UsersServices.getDeletedUsers(name ? name : "", newPage ? newPage : 1, rowsPerPage ? rowsPerPage : "")
      if (status === true && responseCode === 200) {
        setDeletedUsers(data.result)
        setCount(data.count)
      }
    }
    catch (error) {
      ErrorToaster(error)
    }
    finally {
      setLoader(false)
    }
  }

  useEffect(() => {
    getDeletedUsers()
  }, [])
  return (
    <Fragment>
      <UserIdCardDialog open={idCardModal} onClose={() => setIdCardModal(!idCardModal)} image={idImg} title={title} />
      <Box sx={{
        backgroundColor: colors.lightGray, p: 3, borderRadius: "12px", width: "calc(100vw - 370px)"
      }} >
        <Grid container alignItems="center" justifyContent="space-between"
          sx={{
            bgcolor: colors.white, p: 2, borderRadius: "12px",
          }}
        >
          <Grid item xs={6}>
            <Typography variant="body1" fontWeight="bold" color="initial">Deleted Users List</Typography>
          </Grid>
          <Grid item xs={6} sx={{ display: "flex", justifyContent: "flex-end" }}>
            <BreadCrumbs currentPage={['Users', 'Deleted Users']} />
          </Grid>
        </Grid>
        {loader ? (
          <Box sx={{ width: 1, textAlign: "center", pt: 5 }}>
            <Loading />
          </Box>
        ) : (
          <Fragment>
            <Box >
              <DataFilter sortData={(stat, id, name) => sortByName(stat, id, name)} data={""} type={"user"} />
            </Box>
            <TableContainer component={Paper}
              sx={{
                '&::-webkit-scrollbar': {
                  bgcolor: colors.white,
                  height: "8px",
                  borderRadius: "10px",
                  cursor: "pointer"
                },
                '&::-webkit-scrollbar-thumb': {
                  bgcolor: colors.primary,
                  borderRadius: "10px",
                  cursor: "pointer"
                },
                "&.MuiPaper-root": {
                  borderRadius: "12px",
                  mt: 2,
                  boxShadow: 0,
                }
              }}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead >
                  <TableRow sx={{ bgcolor: colors.lightGray }}>
                    {tableHead.map((item, index) =>
                      <TableCell key={index} sx={{ fontWeight: "bold" }} >{item}</TableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {deletedUsers.map((row, index) => (
                    <TableRow
                      key={index}
                    >
                      <TableCell component="th" scope="row" >
                        {getDate(row.createdAt)}
                      </TableCell>
                      <TableCell  >
                        {getDate(row.deleted_at)}
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2" color="initial" noWrap>{row.name ? row.name : "-"}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2" color="initial" noWrap>{row.deleted_email ?? "-"}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2" color="initial" noWrap>{row.deleted_phone ? row.deleted_phone : "no phone"}</Typography>
                      </TableCell>
                      <TableCell>
                        <Tooltip title="view">
                          <IconButton onClick={() => { setIdCardModal(!idCardModal); setIdImg([row.nic_front, row.nic_back]); setTitle("Emirates Id") }}>
                            <Visibility />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                      <TableCell>
                        <Tooltip title="view">
                          <IconButton onClick={() => { setIdCardModal(!idCardModal); setIdImg([row.insurance]); setTitle("Insurance Card") }} >
                            <Visibility />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Table sx={{ marginTop: "12px" }} >
              <TablePagination
                sx={{ borderBottom: "none", bgcolor: colors.white, borderRadius: "12px" }}
                rowsPerPageOptions={[10, 20, 30]}
                colSpan={12}
                count={+count}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    'aria-label': 'rows per page',
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </Table>
          </Fragment>
        )}
      </Box>
    </Fragment>
  )
}

export default DeletedUsers