import { get, post, patch, deleted } from "../index";
import SettingsRoutes from "./Settings.Routes";

const SettingServices = {
  appSettings: async () => {
    const data = get(SettingsRoutes.appSettings);
    return data;
  },
  updateAppSettings: async (obj) => {
    const data = patch(
      SettingsRoutes.updateAppSettings + `?id=63f5bedef0eaa4b68f628617`,
      obj
    );
    return data;
  },
  allStats: async (selectedYear) => {
    const data = get(SettingsRoutes.allStats + `?currentYear=${selectedYear}`);
    return data;
  },
};

export default SettingServices;
