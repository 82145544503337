import React, { useState, useEffect, Fragment } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Layout } from "../../layout/Layout";
//* Required Components
import colors from "./../../styles/colors";
import BreadCrumbs from "./../../components/BreadCrumbs/BreadCrumbs";
import TextEditor from "../../components/TextEditor/TextEditor";
import BlogsServices from "./../../apis/Blogs/BlogsServices";
import { InputField } from "./../../components/Fields/TextField";
import { PrimaryButton } from "./../../components/Button/Button";
import { SuccessToaster } from "../../components/Toaster/Toaster";
import { ErrorToaster } from "./../../components/Toaster/Toaster";
import { baseUrl } from "./../../../axios";
//* MUI Imports
import { Close, CloudUpload } from "@mui/icons-material";
import {
  Box,
  CardMedia,
  Grid,
  TextField,
  Typography,
  IconButton,
} from "@mui/material";

const EditBlog = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();
  const [content, setContent] = useState("");
  const [contentAr, setContentAr] = useState("");
  const [imgPath, setImgPath] = useState("");
  const [blogImg, setBlogImg] = useState("");
  const [blogId, setBlogId] = useState("");
  const [fileInputKey, setFileInputKey] = useState(Date.now());
  const getTextValue = (value) => {
    setContent(value);
  };
  const getTextArValue = (value) => {
    setContentAr(value);
  };
  const blogSubmit = async (formData) => {
    try {
      const obj = {
        id: blogId,
        title: formData.title,
        title_ar: formData.titleAr,
        short_description: formData.desc,
        short_description_ar: formData.descAr,
        content: content,
        content_ar: contentAr,
        image: imgPath,
      };

      const { data, message } = await BlogsServices.editBlog(obj);
      if (data === null) return;
      SuccessToaster(message);
      reset();
      setTimeout(() => navigate("/blogs"), 1000);
    } catch (error) {
      ErrorToaster(error);
    }
  };
  const blogPicture = async (image) => {
    const validImageTypes = ["image/png", "image/jpeg", "image/jpg"];
    if (!validImageTypes.includes(image?.type)) {
      ErrorToaster("Please upload a valid image file (PNG, JPEG, or JPG)");
    } else {
      try {
        let formData = new FormData();
        formData.append("imagePath", image);
        const { data } = await BlogsServices.blogPicture(formData);
        if (data === null) return;
        setImgPath(data.imgPath);
        setBlogImg(data.imgPath);
        setFileInputKey(Date.now());
        // SuccessToaster("Image Uploaded")
      } catch (error) {
        ErrorToaster(error);
      }
    }
  };
  //* For Default/AutoFill Values
  const defaultData = () => {
    setValue("title", state?.data.title);
    setValue("titleAr", state?.data.title_ar);
    setValue("desc", state?.data.short_description);
    setValue("descAr", state?.data.short_description_ar);
    setContent(state?.data.content);
    setContentAr(state?.data.content_ar);
    setBlogImg(state?.data.image);
    setImgPath(state?.data.image);
    setBlogId(state?.data._id);
  };
  useEffect(() => {
    defaultData();
  }, []);

  return (
    <Fragment>
      <Box
        sx={{
          backgroundColor: colors.lightGray,
          p: 3,
          borderRadius: "12px",
          width: "calc(100vw - 370px)",
        }}
      >
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          sx={{ bgcolor: colors.white, p: 2, borderRadius: "12px" }}
        >
          <Grid item xs={6}>
            <Typography variant="body1" fontWeight="bold" color="initial">
              Edit Blog
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <BreadCrumbs currentPage={["Blogs", "Edit Blog"]} />
          </Grid>
        </Grid>
        {content ? (
          <Typography component={"form"} onSubmit={handleSubmit(blogSubmit)}>
            <Box
              sx={{ bgcolor: colors.white, p: 3, borderRadius: "12px", mt: 2 }}
            >
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <InputField
                    label="Title"
                    register={register("title", {
                      required: "Please fill all fields",
                      // onChange: () => setEnableBtn(true),
                      pattern: {
                        message: "Please fill all fields",
                      },
                    })}
                  />
                </Grid>
                <Grid item xs={6}>
                  <InputField
                    label="Arabic Title"
                    register={register("titleAr", {
                      required: "Please fill all fields",
                      // onChange: () => setEnableBtn(true),
                      pattern: {
                        message: "Please fill all fields",
                      },
                    })}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    sx={{ width: "100%", mb: 2 }}
                    multiline
                    rows={3}
                    label=" Short Description"
                    {...register("desc", {
                      required: "Please fill all fields",
                      // onChange: () => setEnableBtn(true),
                      pattern: {
                        message: "Please fill all fields",
                      },
                    })}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    sx={{ width: "100%" }}
                    multiline
                    rows={3}
                    label="Short Arabic Description"
                    {...register("descAr", {
                      required: "Please fill all fields",
                      // onChange: () => setEnableBtn(true),
                      pattern: {
                        message: "Please fill all fields",
                      },
                    })}
                  />
                </Grid>
                <Grid item xs={12} display="flex" justifyContent="center">
                  <Box>
                    <Typography>Content</Typography>
                    <TextEditor getValue={getTextValue} description={content} />
                  </Box>
                </Grid>
                <Grid item xs={12} display="flex" justifyContent="center">
                  <Box>
                    <Typography>Arabic Content</Typography>
                    <TextEditor
                      getValue={getTextArValue}
                      description={contentAr}
                      type={true}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  {blogImg !== "" && (
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <Box sx={{ flexShrink: 0, position: "relative" }}>
                        <CardMedia
                          component={"img"}
                          image={baseUrl + blogImg}
                          sx={{
                            height: "150px",
                            width: "150px",
                            objectFit: "contain",
                          }}
                        />
                        <IconButton
                          component="span"
                          disableRipple
                          sx={{
                            position: "absolute",
                            right: "-6px",
                            top: "-6px",
                            padding: "1px",
                          }}
                          onClick={() => setBlogImg("")}
                        >
                          <Close sx={{ color: colors.danger }} />
                        </IconButton>
                      </Box>
                    </Box>
                  )}
                  <Box
                    sx={{
                      display: blogImg ? "none" : "flex",
                      gap: "10px",
                      justifyContent: "center",
                    }}
                  >
                    <span
                      className="btn btn-primary btn-file"
                      style={{
                        position: "relative",
                        overflow: "hidden",
                        color: "white",
                        height: "50px",
                        width: "fit-content",
                        padding: "12px",
                        borderRadius: "5px",
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <CloudUpload
                        sx={{
                          fontSize: "60px",
                          color: colors.primary,
                          cursor: "pointer",
                          "&:hover": {
                            color: colors.secondary,
                            transition: "0.3s ease-in-out",
                          },
                        }}
                      />
                      <input
                        // multiple
                        type="file"
                        style={{
                          position: " absolute",
                          filter: "alpha(opacity=0)",
                          top: "0",
                          right: "0",
                          align: "right",
                          minWidth: "100%",
                          minHeight: "100%",
                          fontSize: " 100px",
                          opacity: "0",
                          outline: "none",
                          background: "white",
                          cursor: "inherit",
                          display: "block",
                        }}
                        onChange={(event) => {
                          blogPicture(event.target.files[0]);
                          setBlogImg(
                            URL.createObjectURL(event.target.files[0])
                          );
                        }}
                      />
                    </span>
                    {/* {
                    image !== ""
                  }
                  <Button >Clear Image</Button> */}
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{ fontSize: "11px", color: colors.ebonyClay }}
                    >
                      Upload Image
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
              <Box
                sx={{
                  mt: 2,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <PrimaryButton
                  data-testId="save-btn"
                  title={"Save Changes"}
                  type="submit"
                  // loading={loading}
                />
              </Box>
            </Box>
          </Typography>
        ) : null}
      </Box>
    </Fragment>
  );
};

export default EditBlog;
