import React, { Fragment, useEffect, useState } from 'react'
import { Layout } from '../../layout/Layout'
import colors from './../../styles/colors';
import BreadCrumbs from './../../components/BreadCrumbs/BreadCrumbs';
import TextEditor from '../../components/TextEditor/TextEditor';
import ContentServices from './../../apis/Content/ContentServices';
//* Required Imports
import { ErrorToaster, SuccessToaster } from './../../components/Toaster/Toaster';
import { PrimaryButton } from '../../components/Button/Button';
import { Loading } from '../../components/UI/Loader';
//* MUI Material
import { Box, Grid, Typography } from '@mui/material';

const AboutUs = () => {
  // *For Loader
  const [loader, setLoader] = useState(true);
  const [content, setContent] = useState('')
  const [contentAr, setContentAr] = useState('')
  const [blogId, setBlogId] = useState('')

  const getTextValue = (value) => {
    setContent(value)
  }
  const getTextArValue = (value) => {
    setContentAr(value)
  }
  const aboutUsContent = async () => {
    try {
      const { data } = await ContentServices.aboutUsContent()
      if (data === null) return
      setBlogId(data.result._id)
      setContent(data.result.content)
      setContentAr(data.result.content_ar)
    }
    catch (error) {
      ErrorToaster(error)
    }
    finally {
      setLoader(false)
    }
  }


  const contentSubmit = async () => {
    try {
      const obj = {
        page_name: "About us",
        content: content,
        content_ar: contentAr,
      }
      const { data, message } = await ContentServices.createContent(blogId, obj);
      if (data === null) return
      SuccessToaster(message)
    }
    catch (error) {
      ErrorToaster(error)
    }
  }

  useEffect(() => {
    aboutUsContent()
  }, [])
  return (
    <Fragment>
      <Box sx={{ backgroundColor: colors.lightGray, p: 3, borderRadius: "12px", width: "calc(100vw - 370px)" }}>
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          sx={{ bgcolor: colors.white, p: 2, borderRadius: "12px", }}
        >
          <Grid item xs={6}>
            <Typography variant="body1" fontWeight="bold" color="initial">About Us</Typography>
          </Grid>
          <Grid item xs={6} sx={{ display: "flex", justifyContent: "flex-end" }}>
            <BreadCrumbs currentPage={['About Us']} />
          </Grid>
        </Grid>
        {loader ? (
          <Box sx={{ width: 1, textAlign: "center", pt: 5 }}>
            <Loading />
          </Box>
        ) : (
          <Box sx={{ bgcolor: colors.white, p: 3, borderRadius: "12px", mt: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} display="flex" justifyContent="center">
                <Box>
                  <Typography>Content</Typography>
                  <TextEditor getValue={getTextValue} description={content} />
                </Box>
              </Grid>
              <Grid item xs={12} display="flex" justifyContent="center">
                <Box>
                  <Typography>Arabic Content</Typography>
                  <TextEditor getValue={getTextArValue} description={contentAr} type={true} />
                </Box>
              </Grid>
            </Grid>
            <Box sx={{ mt: 2, display: "flex", alignItems: "center", justifyContent: "center" }}>
              <PrimaryButton
                data-testId="save-btn"
                title={"Save"}
                onClick={contentSubmit}
              // loading={loading}
              />
            </Box>
          </Box>
        )}
      </Box>
    </Fragment>
  )
}

export default AboutUs