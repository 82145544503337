import { get, post, patch, deleted } from '../index'
import BlogsRoutes from './Blogs.Routes';

const BlogsServices = {
  getAllBlogs: async (page, rowsPerPage) => {
    const data = await get(BlogsRoutes.getAllBlogs + `?perPage=${rowsPerPage}` + `&page=${page}`)
    return data
  },
  createBlog: async (obj) => {
    const data = await post(BlogsRoutes.createBlog, obj)
    return data
  },
  deleteBlog: async (id) => {
    const data = await patch(BlogsRoutes.deleteBlog + `?id=${id}`)
    return data
  },
  blogPicture: async (obj) => {
    const data = await post(BlogsRoutes.blogPicture, obj)
    return data
  },
  editBlog: async (obj) => {
    const data = await patch(BlogsRoutes.editBlog, obj)
    return data
  },
}

export default BlogsServices