import { get, post, patch, deleted } from '../index'
import OrderRoutes from './Order.Routes';

const OrderServices = {
  getAllOrders: async (status, id, name, type, from, to, page, rowsPerPage) => {
    const data = await get(OrderRoutes.getAllOrders + `?perPage=${rowsPerPage}` + `&page=${page}` +
      `&status=${status}` + `&order_id=${id}` + `&orderType=${type}` + `&username=${name}` + `&fromDate=${from}` + `&toDate=${to}`)
    return data
  },
  quoteOrder: async (obj) => {
    const data = await post(OrderRoutes.quoteOrders, obj);
    return data
  },
  getOrderDetails: async (id) => {
    const data = await get(OrderRoutes.getOrderDetails + `?id=${id}`)
    return data
  },
  orderStatus: async () => {
    const data = await get(OrderRoutes.orderStatus)
    return data
  },
  completeOrder: async (id) => {
    const data = await post(OrderRoutes.completeOrder + `?id=${id}`)
    return data
  },
  sendNotification: async (body) => {
    const data = await post(OrderRoutes.orderNotification, body)
    return data
  }
}

export default OrderServices