import React, { useState, Fragment, useEffect } from "react";
//* Component Imports
import PrimaryCard from "../../components/Cards/PrimaryCard";
import { Layout } from "../../layout/Layout";
import SecondaryCard from "./../../components/Cards/SecondaryCard";
import RecentOrdersCard from "../../components/Cards/RecentOrdersCard";
import SettingServices from "./../../apis/Settings/SettingsServices";
import { Loading } from "../../components/UI/Loader";
import { ErrorToaster } from "./../../components/Toaster/Toaster";
import colors from "./../../styles/colors";
//* Chart Imports
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import clsx from "clsx";
//* MUI Imports
import { Box, Grid, MenuItem, Select } from "@mui/material";
import {
  Vaccines,
  CalendarMonth,
  Person4,
  MedicalServices,
} from "@mui/icons-material";
import { makeStyles } from "@mui/styles";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const useStyles = makeStyles({
  iconStyle: {
    fontSize: "30px",
    borderRadius: "8px",
    zIndex: 99,
    padding: "10px",
    position: "relative",
    top: -15,
  },
  smallIconStyle: {
    borderRadius: "8px",
    padding: 8,
    fontSize: "24px",
    marginRight: 12,
  },
  typeIconBgColor: {
    backgroundColor: "#b9b9b9",
  },
  makeIconBgColor: {
    backgroundColor: "#eddaa98f",
  },
});

const Dashboard = () => {
  const currentYear = new Date().getFullYear();
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const handleChange = (event) => {
    setSelectedYear(event.target.value);
  };

  const [dStats, setDStats] = useState([]);
  const [loader, setLoader] = useState(true);
  // Chart  Object
  const options = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
      },
      title: {
        display: true,
        text: "Month Wise Orders",
        fontSize: "18px",
      },
    },
  };

  const labels = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const monthlyOrderData = {
    label: "Orders",
    data: labels.map((month) => {
      const foundMonth = dStats?.monthlyOrderGraph?.find(
        (item) => item.month === month
      );
      return foundMonth ? foundMonth.count : 0;
    }),
    backgroundColor: colors.secondary,
    borderRadius: 6,
  };

  const data = {
    labels,
    datasets: [monthlyOrderData],
  };

  const stats = async () => {
    try {
      const { data } = await SettingServices.allStats(selectedYear);
      if (data === null) return;
      setDStats(data);
    } catch (error) {
      ErrorToaster(error);
    } finally {
      setLoader(false);
    }
  };
  useEffect(() => {
    stats();
  }, [selectedYear]);

  const classes = useStyles();
  return (
    <Fragment>
      <Grid
        container
        justifyContent={"space-between"}
        sx={{
          backgroundColor: colors.lightGray,
          borderRadius: "12px",
          p: 3,
          width: "100%",
        }}
      >
        {loader ? (
          <Box sx={{ width: 1, textAlign: "center", pt: 5 }}>
            <Loading />
          </Box>
        ) : (
          <Fragment>
            <Grid item xs={12} md={3.9}>
              <PrimaryCard
                icon={
                  <Vaccines
                    className={classes.iconStyle}
                    sx={{ color: colors.primary }}
                  />
                }
                number={dStats?.ordersCount}
                name={"Total Orders"}
                color={colors.white}
                bgColor={colors.primary}
                circle1={"#ffffffbd"}
                circle2={"#ffffff40"}
              />
            </Grid>

            <Grid item xs={12} md={3.9}>
              <PrimaryCard
                icon={
                  <Person4
                    className={classes.iconStyle}
                    sx={{ color: colors.mercury }}
                  />
                }
                number={dStats?.usersCount}
                name={"Total Users"}
                color={colors.cloud}
                bgColor={colors.secondary}
                bgImage={colors.secondaryTopDown}
                circle1={"#ffffff29"}
                circle2={"#0000001f"}
              />
            </Grid>

            <Grid item xs={12} md={3.9}>
              <SecondaryCard
                icon={
                  <CalendarMonth
                    className={clsx(
                      classes.smallIconStyle,
                      classes.typeIconBgColor
                    )}
                    color={colors.secondaryLight}
                  />
                }
                number={dStats?.quotedCount}
                name={"Quoted Orders"}
                nameColor={colors.cloud}
                color={colors.mercury}
                bgColor={colors.secondary}
                circle1={"#ffffffbd"}
                circle2={"#ffffff40"}
              />
              <SecondaryCard
                icon={
                  <MedicalServices
                    className={clsx(
                      classes.smallIconStyle,
                      classes.makeIconBgColor
                    )}
                    color={colors.artyClickAmber}
                  />
                }
                number={dStats?.requestCount}
                name={"Pending Order Requests"}
                color={colors.white}
                nameColor={colors.white}
                bgColor={colors.eggSour}
                bgImage={colors.accepted}
                circle1={"#07ffa321"}
                circle2={"#4aa58b"}
              />
            </Grid>

            <Grid item xs={12} md={7.9}>
              <Box
                sx={{
                  backgroundColor: colors.white,
                  my: 2,
                  p: 2,
                  borderRadius: "12px",
                  height: 350,
                  position: "relative",
                }}
              >
                <Box
                  sx={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    padding: "5px",
                  }}
                >
                  <Select
                    sx={{
                      height: "40px",
                    }}
                    value={selectedYear}
                    onChange={handleChange}
                    label="Year"
                  >
                    {Array.from(
                      { length: currentYear - 2019 },
                      (_, index) => 2020 + index
                    ).map((year) => (
                      <MenuItem key={year} value={year}>
                        {year}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
                <Bar options={options} data={data} />
              </Box>
            </Grid>
            <Grid item xs={3.9}>
              <RecentOrdersCard />
            </Grid>
          </Fragment>
        )}
      </Grid>
    </Fragment>
  );
};

export default Dashboard;
