import React, { Fragment, useState, useEffect } from "react";
import MarketplaceVouchersServices from "../../../apis/Marketplace/Vouchers/MarketplaceVoucherServices";
import { PrimaryButton } from "../../../components/Button/Button";
import BreadCrumbs from "../../../components/BreadCrumbs/BreadCrumbs";
import { useForm } from "react-hook-form";
import colors from "../../../styles/colors";
import { InputField } from "../../../components/Fields/TextField";
import { useNavigate } from "react-router-dom";
import {
  ErrorToaster,
  SuccessToaster,
} from "../../../components/Toaster/Toaster";
import {
  Box,
  Grid,
  Typography,
  Switch,
  FormControlLabel,
  FormLabel,
} from "@mui/material";

function CreateMVoucher() {
  const [loading, setLoading] = useState(false);
  const [selectedDiscount, setSelectedDiscount] = useState(false);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const currentDate = new Date().toISOString().split("T")[0];

  const createVoucher = async (formData) => {
    setLoading(true);
    try {
      const minimumAmount = parseFloat(formData.minimumAmount);
      const discountValue = parseFloat(formData.discountPercent);
      let obj = {
        code: formData.code,
        discount_amount: selectedDiscount === true ? discountValue : 0,
        discount_percent: selectedDiscount === true ? 0 : discountValue,
        valid_from: formData.validFrom,
        valid_to: formData.validTo,
        usage_limit: formData.usageLimit,
        minimum_amount: minimumAmount,
      };
      const result = await MarketplaceVouchersServices.createVoucher(obj);
      const { data, message } = result;
      if (data === null) return;
      SuccessToaster(message);
      setTimeout(() => navigate("/marketplace/vouchers"), 1000);
    } catch (error) {
      ErrorToaster(error);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Fragment>
      <Box
        sx={{
          backgroundColor: colors.lightGray,
          p: 3,
          borderRadius: "12px",
          width: "calc(100vw - 370px)",
        }}
      >
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          sx={{ bgcolor: colors.white, p: 2, borderRadius: "12px" }}
        >
          <Grid item xs={6}>
            <Typography variant="body1" fontWeight="bold" color="initial">
              Create Voucher
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <BreadCrumbs currentPage={["Vouchers", "Create Voucher"]} />
          </Grid>
        </Grid>

        <Box sx={{ bgcolor: colors.white, p: 3, borderRadius: "12px", mt: 2 }}>
          <Typography component={"form"} onSubmit={handleSubmit(createVoucher)}>
            <Grid container spacing={2} alignItems={"flex-end"}>
              <Grid item md={4}>
                <InputField
                  label="Code"
                  register={register("code", {
                    required: "Please fill the field",
                  })}
                  error={errors?.code?.message}
                />
              </Grid>
              <Grid item md={5}>
                <Grid container spacing={2} alignItems={"center"}>
                  <Grid item md={9}>
                    <InputField
                      type={"number"}
                      label={
                        selectedDiscount === false
                          ? "Discount Percent"
                          : "Discount Amount"
                      }
                      register={register("discountPercent", {
                        required: "Please fill the field",
                      })}
                      error={errors?.discountPercent?.message}
                    />
                  </Grid>
                  <Grid item md={3} p={"0 !important"}>
                    <FormControlLabel
                      control={
                        <Switch
                          onChange={(e) =>
                            setSelectedDiscount(e.target.checked)
                          }
                          sx={{ m: 1 }}
                        />
                      }
                      label={selectedDiscount ? "AED" : "Per%"}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={3}>
                <InputField
                  label="Usage Limit"
                  register={register("usageLimit", {
                    required: "Please fill the field",
                    pattern: {
                      value: /^[0-9]*$/,
                      message: "Please enter a valid numeric value",
                    },
                  })}
                  error={errors?.usageLimit?.message}
                />
              </Grid>
              <Grid item md={4}>
                <FormLabel>Valid From</FormLabel>
                <InputField
                  type={"date"}
                  // label="Valid From"
                  min={currentDate}
                  placeholder={"Valid From"}
                  register={register("validFrom", {
                    required: "Please fill the field",
                  })}
                  error={errors?.validFrom?.message}
                />
              </Grid>
              <Grid item md={4}>
                <FormLabel>Valid To</FormLabel>
                <InputField
                  type={"date"}
                  // label="Valid To"
                  min={currentDate}
                  placeholder={"Valid To"}
                  register={register("validTo", {
                    required: "Please fill the field",
                  })}
                  error={errors?.validTo?.message}
                />
              </Grid>
              <Grid item md={4}>
                <InputField
                  type={"number"}
                  label="Minimum Amount"
                  register={register("minimumAmount", {
                    required: "Please fill the field",
                  })}
                  error={errors?.minimumAmount?.message}
                />
              </Grid>
            </Grid>
            <Box
              sx={{
                mt: 2,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <PrimaryButton
                data-testId="save-btn"
                title={"Create"}
                type="submit"
                loading={loading}
              />
            </Box>
          </Typography>
        </Box>
      </Box>
    </Fragment>
  );
}

export default CreateMVoucher;
