import { get, post, patch, deleted } from "../../index";
import OffersRoutes from "./Offers.Routes";

const OffersServices = {
  getAlloffers: async (page, rowsPerPage) => {
    const data = await get(
      OffersRoutes.getAllOffers + `?page=${page}` + `&limit=${rowsPerPage}`
    );
    return data;
  },
  createOffer: async (obj) => {
    const data = await post(OffersRoutes.createOffer, obj);
    return data;
  },
  updateOffer: async (obj) => {
    const data = await patch(OffersRoutes.updateOffer, obj);
    return data;
  },
  deleteOffer: async (id) => {
    const data = await deleted(OffersRoutes.deleteOffer + `?id=${id}`);
    return data;
  },
};

export default OffersServices;
