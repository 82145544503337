import { get, post, patch, deleted } from "../../index";
import MOrderRoutes from "./MOrder.Routes";

const MOrderServices = {
  getAllOrders: async (from, to, status, id, rowsPerPage, page, name) => {
    const data = await get(
      MOrderRoutes.getAllOrders +
        `?fromDate=${from}` +
        `&toDate=${to}` +
        `&status=${status}` +
        `&order_id=${id}` +
        `&perPage=${rowsPerPage}` +
        `&page=${page}` +
        `&username=${name}`
    );
    return data;
  },
  quoteOrder: async (obj) => {
    const data = await post(MOrderRoutes.quoteOrders, obj);
    return data;
  },
  getOrderDetails: async (id) => {
    const data = await get(MOrderRoutes.getOrderDetails + `?id=${id}`);
    return data;
  },
  updateOrderStatus: async (obj) => {
    const data = await patch(MOrderRoutes.updateOrderStatus, obj);
    return data;
  },
  getOrderStatus: async () => {
    const data = await get(MOrderRoutes.getOrderStatus);
    return data;
  },
  completeOrder: async (id) => {
    const data = await post(MOrderRoutes.completeOrder + `?id=${id}`);
    return data;
  },
  sendNotification: async (body) => {
    const data = await post(MOrderRoutes.orderNotification, body);
    return data;
  },
};

export default MOrderServices;
