import { useState } from "react";

function useProvideAuth() {
  const [user, setUser] = useState(localStorage.getItem("token"));
  const [userName, setUserName] = useState(localStorage.getItem("name"));
  const [picture, setPicture] = useState(localStorage.getItem("picture"));
  const [phone, setPhone] = useState(localStorage.getItem("phone"));
  const [mail, setMail] = useState(localStorage.getItem("mail"));
  const [role, setRole] = useState(localStorage.getItem("role"));

  // *User
  const userLogin = (data) => {
    localStorage.setItem("token", data.token);
    localStorage.setItem("name", data.name);
    localStorage.setItem("picture", data.picture);
    localStorage.setItem("phone", data.phone);
    localStorage.setItem("mail", data.email);
    localStorage.setItem("role", data.role_code);
    setUser(data.token);
    setUserName(data.name);
    setPicture(data.picture);
    setPhone(data.phone);
    setMail(data.mail);
    setRole(data.role_code);
  };

  const userLogout = async () => {
    setUser(null);
    localStorage.clear();
  };

  // *Verify Token
  const verifyToken = () => {
    const token = localStorage.getItem("token");
    const name = localStorage.getItem("name");
    setUser(token);
    setUserName(name);
  };

  return {
    user,
    userName,
    picture,
    phone,
    mail,
    role,
    userLogin,
    userLogout,
    verifyToken,
  };
}

export default useProvideAuth;
