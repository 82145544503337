import { Close, Upload } from "@mui/icons-material";
import {
  Box,
  Dialog,
  DialogTitle,
  IconButton,
  Grid,
  Typography,
  InputAdornment,
  Avatar,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { PrimaryButton, SecondaryButton } from "../Button/Button";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { InputField, OutlineInputField } from "./../Fields/TextField";
import { useState } from "react";
import colors from "../../styles/colors";
import ProfileServices from "../../apis/Profile/ProfileServices";
import { ErrorToaster } from "../Toaster/Toaster";
import { baseUrl } from "../../../axios";

function ProfileDialog({ open, onClose, title, action, profile }) {
  const [image, setImage] = useState(null);
  const [imagePath, setImagePath] = useState("");

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();
  const handleImageChange = async (image) => {
    const validImageTypes = ["image/png", "image/jpeg", "image/jpg"];
    if (!validImageTypes.includes(image?.type)) {
      ErrorToaster("Please upload a valid image file (PNG, JPEG, or JPG)");
    } else {
      try {
        // const imgData = URL.createObjectURL(image);
        let formData = new FormData();
        formData.append("imagePath", image);
        const { data } = await ProfileServices.updatePicture(formData);
        if (data === null) return;
        setImagePath(data.imgPath);
        setImage(data.imgPath);
      } catch (error) {
        ErrorToaster(error);
      }
    }
  };

  return (
    <Dialog
      data-testId="dialog-box"
      maxWidth="xs"
      open={open}
      sx={{
        "& .MuiDialog-paper": {
          width: "80%",
          height: "auto",
          borderRadius: 2,
          py: { xs: 2, md: 4 },
          px: { xs: 3, md: 6 },
        },
      }}
    >
      <IconButton
        data-testId="close-dialog"
        color="primary"
        onClick={() => onClose()}
        sx={{ position: "absolute", right: 13, top: 13 }}
      >
        <Close />
      </IconButton>
      <DialogTitle
        sx={{ textAlign: "center", fontSize: "18px", fontWeight: 700 }}
      >
        {title}
      </DialogTitle>
      <Typography
        component={"form"}
        onSubmit={handleSubmit((formData) => action(formData, imagePath))}
      >
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <Box
              display="flex"
              justifyContent="center"
              sx={{ position: "relative" }}
            >
              <Avatar
                sx={{ width: 184, height: 184 }}
                src={image ? baseUrl + image : baseUrl + profile?.picture}
                alt="userProfile"
              />
              <span
                className="btn btn-primary btn-file"
                style={{
                  cursor: "pointer",
                  "&:hover": { bgcolor: colors.cloud },
                }}
              >
                <Upload
                  sx={{
                    boxShadow: `rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px`,
                    bgcolor: "#ffffffc9",
                    position: "absolute",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: 36,
                    height: 36,
                    borderRadius: 40,
                    top: 130,
                    left: 130,
                    "&:hover": { bgcolor: colors.cloud },
                  }}
                />
                <input
                  // multiple
                  type="file"
                  style={{
                    position: " absolute",
                    filter: "alpha(opacity=0)",
                    top: "0",
                    right: "0",
                    align: "right",
                    minWidth: "100%",
                    minHeight: "100%",
                    fontSize: " 100px",
                    opacity: "0",
                    outline: "none",
                    background: "white",
                    cursor: "inherit",
                    display: "block",
                    "&:hover": { bgcolor: colors.cloud },
                  }}
                  onChange={(event) => {
                    handleImageChange(event.target.files[0]);
                  }}
                />
              </span>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <InputField
              label="Name"
              type={"text"}
              maxLength={25}
              defaultValue={profile.name}
              register={register("name")}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
            <PrimaryButton
              data-testId="yes-btn"
              title="Save Changes"
              type="submit"
              onClick={() => onClose()}
            />
          </Grid>
        </Grid>
      </Typography>
    </Dialog>
  );
}

export default ProfileDialog;
