import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Layout } from "./../../layout/Layout";
import colors from "./../../styles/colors";
import BreadCrumbs from "./../../components/BreadCrumbs/BreadCrumbs";
import { InputField } from "./../../components/Fields/TextField";
import { PrimaryButton } from "./../../components/Button/Button";
import {
  ErrorToaster,
  SuccessToaster,
} from "./../../components/Toaster/Toaster";
import VoucherServices from "./../../apis/Voucher/VoucherServices";
import { Typography, Box, Grid, FormControl, TextField } from "@mui/material";

const CreateVoucher = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  // const [setting, setSetting] = useState([]);
  // const [enableBtn, setEnableBtn] = useState(false);
  // const [loader, setLoader] = useState(false);
  const [enableLimit, setEnableLimit] = useState(false);
  const currentDate = new Date().toISOString().split("T")[0];
  // const [checked, setChecked] = useState(false);

  // const handleChange = (event) => {
  //   setChecked(event.target.checked);
  //   setEnableLimit(!enableLimit)
  // };

  const createVoucher = async (formData) => {
    try {
      let obj = {
        code: formData.code,
        discount_amount: Number(formData.discount),
        usage_limit: Number(formData.limit),
        valid_from: formData.from,
        valid_to: formData.to,
      };
      const { data, message } = await VoucherServices.createVoucher(obj);
      if (data === null) return;
      SuccessToaster(message);
      setTimeout(() => navigate("/vouchers"), 1000);
    } catch (error) {
      ErrorToaster(error);
    }
  };

  return (
    <Fragment>
      <Box
        sx={{
          backgroundColor: colors.lightGray,
          p: 3,
          borderRadius: "12px",
          width: "calc(100vw - 370px)",
        }}
      >
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          sx={{ bgcolor: colors.white, p: 2, borderRadius: "12px" }}
        >
          <Grid item xs={6}>
            <Typography variant="body1" fontWeight="bold" color="initial">
              Create Voucher
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <BreadCrumbs currentPage={["Vouchers", "Create Voucher"]} />
          </Grid>
        </Grid>

        <Box sx={{ bgcolor: colors.white, p: 3, borderRadius: "12px", mt: 2 }}>
          <Typography component={"form"} onSubmit={handleSubmit(createVoucher)}>
            <Grid container spacing={2} alignItems="center">
              {/* GST */}
              <Grid item xs={12} md={6}>
                <InputField
                  label="Voucher Code"
                  register={register("code", {
                    required: "Please fill all fields",
                  })}
                  error={errors?.code?.message}
                />
              </Grid>
              {/* VAT */}
              <Grid item xs={12} md={6}>
                <InputField
                  min={0.1}
                  max={10000}
                  label="Discount Amount"
                  type="number"
                  register={register("discount", {
                    required: "Please fill all fields",
                  })}
                  error={errors?.discount?.message}
                />
              </Grid>
              {/* Usage Limit */}
              <Grid item xs={12} md={6}>
                <InputField
                  disable={enableLimit ? "disable" : ""}
                  type="number"
                  label="Usage Limit"
                  register={register("limit", {
                    required: "Please fill all fields",
                    pattern: {
                      value: /^[0-9]*$/,
                      message: "Please enter a valid numeric value",
                    },
                  })}
                  error={errors?.limit?.message}
                />
                {/* <Grid item xs={6} display='flex' alignItems='center'>
                        <Checkbox
                          checked={checked}
                          onChange={handleChange}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                        <Typography sx={{ color: colors.gray }}>Unlimited</Typography>
                      </Grid> */}
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl variant="standard" fullWidth>
                  <TextField
                    // onChange={(e) => (setFromDate(e.target.value))}
                    size="small"
                    label="From Date"
                    type="date"
                    InputLabelProps={{ shrink: true, required: true }}
                    InputProps={{ inputProps: { min: currentDate } }}
                    sx={{ mb: 2 }}
                    // sx={{ input: { cursor: 'pointer' } }}
                    variant="outlined"
                    error={errors?.from?.message && true}
                    helperText={errors?.to?.message}
                    {...register("from", {
                      required: "Please select a date",
                    })}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl variant="standard" fullWidth>
                  <TextField
                    // onChange={(e) => (setFromDate(e.target.value))}
                    size="small"
                    label="To Date"
                    type="date"
                    InputLabelProps={{ shrink: true, required: true }}
                    InputProps={{ inputProps: { min: currentDate } }}
                    sx={{ mb: 2 }}
                    // sx={{ input: { cursor: 'pointer' } }}
                    variant="outlined"
                    error={errors?.to?.message && true}
                    helperText={errors?.to?.message}
                    {...register("to", {
                      required: "Please select a date",
                    })}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Box
              sx={{
                mt: 2,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {/* <SecondaryButton
                    data-testId="back-btn"
                    title="Go Back"
                    onClick={() => navigate('/')}
                  /> */}

              <PrimaryButton
                data-testId="save-btn"
                title={"Create"}
                type="submit"
              // loading={loading}
              />
            </Box>
          </Typography>
        </Box>
      </Box>
    </Fragment>
  );
};

export default CreateVoucher;
