import React, { Fragment, useState, useEffect } from "react";
import ProductsServices from "../../../apis/Marketplace/Products/ProductsServices";
import CategoriesServices from "../../../apis/Marketplace/Categories/CategoriesServices";
import OffersServices from "../../../apis/Marketplace/Offers/OffersServices";
import PharmaciesServices from "../../../apis/Marketplace/Pharmacies/PharmaciesServices";
import {
  Box,
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  IconButton,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  Collapse,
  FormControl,
  TextField,
  Button,
} from "@mui/material";
import colors from "../../../styles/colors";
import BreadCrumbs from "../../../components/BreadCrumbs/BreadCrumbs";
import {
  PrimaryButton,
  SecondaryButton,
} from "../../../components/Button/Button";
import {
  Link,
  useSearchParams,
  useNavigate,
  useLocation,
} from "react-router-dom";
import {
  CloudUpload,
  Create,
  DeleteForever,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from "@mui/icons-material";
import {
  ErrorToaster,
  SuccessToaster,
} from "../../../components/Toaster/Toaster";
import ConfirmationDialog from "../../../components/Dialog/ConfirmationDialog";
import { useTheme } from "@emotion/react";
import { Loading } from "../../../components/UI/Loader";
import { baseUrl } from "../../../../axios";
import { FilterAltTwoTone, Search } from "@mui/icons-material";
import SelectField from "../../../components/Fields/SelectField";
import { useForm } from "react-hook-form";
import { InputField } from "../../../components/Fields/TextField";

function BulkDialog({ open, onClose }) {
  const [fileSelected, setFileSelected] = useState(null);
  const {
    register,
    formState: { errors },
  } = useForm();

  const uploadFile = async () => {
    try {
      if (fileSelected) {
        const formData = new FormData();
        formData.append("csvFile", fileSelected);
        const { data, message } = await ProductsServices.createBulk(formData);
        if (data === null) return;
        SuccessToaster(message);
        setFileSelected("");
        onClose();
      }
    } catch (error) {
      ErrorToaster(error);
    }
  };
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Upload Bulk</DialogTitle>
      <DialogContent>
        <Typography>
          <input
            id="uploadBulk"
            type="file"
            style={{ display: "none" }}
            register={register("file", { required: "Please upload file" })}
            onChange={(e) => setFileSelected(e.target.files[0])}
          />
          <label
            htmlFor="uploadBulk"
            style={{ display: "flex", justifyContent: "center" }}
          >
            {fileSelected ? (
              fileSelected.name
            ) : (
              <CloudUpload
                sx={{
                  display: "block",
                  fontSize: "60px",
                  color: colors.primary,
                  cursor: "pointer",
                  "&:hover": {
                    color: colors.secondary,
                    transition: "0.3s ease-in-out",
                  },
                }}
              />
            )}
          </label>
        </Typography>
        <Box
          sx={{
            display: fileSelected === null ? "none" : "flex",
            justifyContent: "center",
          }}
        >
          <PrimaryButton title={"Upload"} onClick={uploadFile} />
        </Box>
      </DialogContent>
    </Dialog>
  );
}

function TablePaginationActions(props) {
  const theme = useTheme;

  const { count, page, rowsPerPage, onPageChange } = props;

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
    </Box>
  );
}

function DataFilter(props) {
  const {
    categoriesData,
    offersData,
    pharmaciesData,
    sortData,
    setCategorySelected,
    setOfferSelected,
    setPharmacySelected,
    setFilterSelected,
  } = props;
  const [filterCollapse, setFilterCollapse] = useState(true);
  const [selectedName, setSelectedName] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [selectedOffer, setSelectedOffer] = useState("");
  const [offerId, setOfferId] = useState("");
  const [selectedPharmacy, setSelectedPharmacy] = useState("");
  const [pharmacyId, setPharmacyId] = useState("");
  const dataType = [
    { _id: "pharmacy", name: "Pharmacy" },
    { _id: "offer", name: "Offer" },
    { _id: "category", name: "Category" },
  ];

  const filterData = () => {
    sortData(selectedName, categoryId, pharmacyId, offerId);
  };

  const clearData = () => {
    // sortData("");
    setFilterSelected("");
    setSelectedOffer("");
    setSelectedCategory("");
    setSelectedPharmacy("");
    // setFilterCollapse(!filterCollapse);
  };

  return (
    <Box sx={{ bgcolor: colors.white, p: 2, borderRadius: "12px", mt: 2 }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="body1" fontWeight="bold" color="initial">
          Filters
        </Typography>
        <IconButton
          onClick={() => setFilterCollapse(!filterCollapse)}
          color="primary"
          sx={{ bgcolor: colors.primaryLight }}
        >
          <FilterAltTwoTone />
        </IconButton>
      </Box>

      <Collapse in={filterCollapse}>
        <Grid container spacing={2}>
          <Grid item md={6}>
            <FormControl fullWidth>
              <SelectField
                setState={(item, name) => {
                  setSelectedId(item);
                  setSelectedName(name);
                  setFilterSelected(item);
                }}
                stateValue={selectedName}
                data={dataType}
                label={"Filter By"}
              />
            </FormControl>
          </Grid>
          <Grid item md={6}>
            <FormControl fullWidth>
              <SelectField
                setState={(item, name) => {
                  setCategoryId(item);
                  setSelectedCategory(name);
                  setCategorySelected(item);
                  setFilterSelected("category");
                }}
                stateValue={selectedCategory}
                data={categoriesData}
                label={"Category"}
              />
            </FormControl>
          </Grid>
          <Grid item md={6}>
            <FormControl fullWidth>
              <SelectField
                setState={(item, name) => {
                  setPharmacyId(item);
                  setSelectedPharmacy(name);
                  setPharmacySelected(item);
                  setFilterSelected("pharmacy");
                }}
                stateValue={selectedPharmacy}
                data={pharmaciesData}
                label={"Pharmacy"}
              />
            </FormControl>
          </Grid>
          <Grid item md={6}>
            <FormControl fullWidth>
              <SelectField
                setState={(item, name) => {
                  setOfferId(item);
                  setSelectedOffer(name);
                  setOfferSelected(item);
                  setFilterSelected("offer");
                }}
                stateValue={selectedOffer}
                data={offersData}
                label={"Offers"}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} display="flex" justifyContent="flex-end">
            <Button
              onClick={clearData}
              variant="outlined"
              sx={{
                mx: 1,
                textTransform: "capitalize",
                minWidth: "100px",
                boxShadow:
                  "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
              }}
            >
              Clear
            </Button>
            <Button
              onClick={filterData}
              variant="contained"
              sx={{
                textTransform: "capitalize",
                minWidth: "100px",
                boxShadow:
                  "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
              }}
            >
              Search
            </Button>
          </Grid>
        </Grid>
      </Collapse>
    </Box>
  );
}

const tableHead = [
  "Name",
  "Price",
  "Discounted Price",
  "Category",
  "Pharmacy",
  "Offer",
  "In Stock",
  "Actions",
];
function Products() {
  const [open, setOpen] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [rowsData, setRowsData] = useState([]);
  const [productId, setProductId] = useState("");
  const [count, setCount] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = useState(0);
  const [loader, setLoader] = useState(true);
  const [checkOffer, setCheckOffer] = useState("");
  const [checkCategory, setCheckCategory] = useState("");
  const [checkPharmacy, setCheckPharmacy] = useState("");
  const [categories, setCategories] = useState([]);
  const [pharmacies, setPharmacies] = useState([]);
  const [offers, setOffers] = useState([]);
  const [categorySelected, setCategorySelected] = useState("");
  const [offerSelected, setOfferSelected] = useState("");
  const [pharmacySelected, setPharmacySelected] = useState("");
  const [filterSelected, setFilterSelected] = useState("");
  const [categoriesCount, setCategoriesCount] = useState(1000);
  const [pharmaciesCount, setPharmaciesCount] = useState(1000);
  const [offersCount, setOffersCount] = useState(1000);
  const [currentPage, setCurrentPage] = useState();
  const role = localStorage.getItem("role");

  const navigate = useNavigate();
  const location = useLocation();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setSearchParams({
      ...searchParams,
      rows: parseInt(searchParams.get("rows")),
      page: newPage + 1,
    });
    getProducts(
      filterSelected ? filterSelected : "",
      checkCategory ? checkCategory : "",
      checkPharmacy ? checkPharmacy : "",
      checkOffer ? checkOffer : "",
      newPage + 1,
      parseInt(searchParams.get("rows"))
    );
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(Math.floor(event.target.value));
    setSearchParams({
      ...searchParams,
      rows: event.target.value,
      page: parseInt(searchParams.get("page")),
    });
    setPage(0);
    getProducts(
      filterSelected ? filterSelected : "",
      checkCategory ? checkCategory : "",
      checkPharmacy ? checkPharmacy : "",
      checkOffer ? checkOffer : "",
      parseInt(searchParams.get("page")) + 1,
      event.target.value
    );
  };

  const getProducts = async (filt, cat, phar, off, newPage, rows) => {
    try {
      setLoader(true);
      const { data } = await ProductsServices.getAllproducts(
        filt ? filt : "",
        cat ? cat : "",
        phar ? phar : "",
        off ? off : "",
        parseInt(searchParams.get("page"))
          ? parseInt(searchParams.get("page"))
          : 1,
        parseInt(searchParams.get("rows"))
          ? parseInt(searchParams.get("rows"))
          : 10
      );
      if (data === null) return;
      setRowsData(data.result);
      setCount(data.count);
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(false);
    }
  };

  const deleteProduct = async () => {
    try {
      const { data, message } = await ProductsServices.deleteProduct(productId);
      if (data === null) return;
      SuccessToaster(message);
      getProducts();
    } catch (error) {
      ErrorToaster(error);
    }
  };

  const getCategories = async () => {
    try {
      const { data } = await CategoriesServices.getAllCategories(
        page,
        categoriesCount
      );
      if (data === null) return;
      setCategories(data.result);
    } catch (error) {
      console.log("error =>", error);
    }
  };

  const getPharmacies = async () => {
    try {
      const { data } = await PharmaciesServices.getAllPharmacies(
        pharmaciesCount,
        page
      );
      if (data === null) return;
      setPharmacies(data.result);
    } catch (error) {
      console.log("error =>", error);
    }
  };

  const getOffers = async () => {
    try {
      const { data } = await OffersServices.getAlloffers(page, offersCount);
      if (data === null) return;
      setOffers(data.result);
    } catch (error) {
      console.log("error =>", error);
    }
  };

  const sort = (filtby, cat, phar, off, page, rows) => {
    setCheckCategory(cat);
    setCheckOffer(off);
    setCheckPharmacy(phar);
    setRowsPerPage(10);
    setPage(0);
    getProducts(
      filtby ? filtby : "",
      cat ? cat : "",
      phar ? phar : "",
      off ? off : "",
      page ? page : 1,
      rows ? rows : ""
    );
  };

  useEffect(() => {
    setSearchParams({
      ...searchParams,
      rows: parseInt(searchParams.get("rows")) || 10,
      page: parseInt(searchParams.get("page")) || 1,
    });
    getProducts();
    getCategories();
    getPharmacies();
    getOffers();
  }, [open, searchParams]);

  return (
    <Fragment>
      <ConfirmationDialog
        open={confirmDialog}
        onClose={() => setConfirmDialog(!confirmDialog)}
        action={deleteProduct}
        title={"Delete this product"}
      />
      <BulkDialog open={open} onClose={() => setOpen(false)} />
      <Box
        sx={{
          backgroundColor: colors.lightGray,
          p: 3,
          borderRadius: "12px",
          width: "calc(100vw - 370px)",
        }}
      >
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          sx={{
            bgcolor: colors.white,
            p: 2,
            borderRadius: "12px",
          }}
        >
          <Grid item xs={6}>
            <Typography variant="body1" fontWeight="bold" color="initial">
              Products
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <BreadCrumbs currentPage={["Products"]} />
          </Grid>
        </Grid>
        {loader ? (
          <Box sx={{ width: 1, textAlign: "center", pt: 5 }}>
            <Loading />
          </Box>
        ) : (
          <Fragment>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                bgcolor: colors.white,
                p: 2,
                borderRadius: "12px",
                textAlign: "right",
                mt: 2,
              }}
            >
              <Box sx={{ mr: "10px" }}>
                <SecondaryButton
                  disabled={role !== "A" ? true : false}
                  title="Add Bulk"
                  onClick={() => setOpen(true)}
                />
              </Box>
              <SecondaryButton
                disabled={role !== "A" ? true : false}
                title="Create Product"
                onClick={() => navigate("/marketplace/products/createProduct")}
              />
            </Box>
            <DataFilter
              // sortData={() =>
              //   sortByStatus(
              //     categories,
              //     offers,
              //     pharmacies
              //   )
              // }
              sortData={() =>
                sort(
                  filterSelected,
                  categorySelected,
                  pharmacySelected,
                  offerSelected,
                  page,
                  rowsPerPage
                )
              }
              categoriesData={categories}
              offersData={offers}
              pharmaciesData={pharmacies}
              setFilterSelected={setFilterSelected}
              setCategorySelected={setCategorySelected}
              setPharmacySelected={setPharmacySelected}
              setOfferSelected={setOfferSelected}
            />
            <TableContainer
              component={Paper}
              sx={{
                "&::-webkit-scrollbar": {
                  bgcolor: colors.white,
                  height: "8px",
                  borderRadius: "10px",
                  cursor: "pointer",
                },
                "&::-webkit-scrollbar-thumb": {
                  bgcolor: colors.primary,
                  borderRadius: "10px",
                  cursor: "pointer",
                },
                "&.MuiPaper-root": {
                  borderRadius: "12px",
                  mt: 2,
                  boxShadow: 0,
                },
              }}
            >
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow sx={{ bgcolor: colors.lightGray }}>
                    {tableHead.map((item, index) => (
                      <TableCell
                        key={index}
                        sx={{
                          fontWeight: "bold",
                        }}
                      >
                        {item}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rowsData.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row">
                        {row.name}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.price}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.discounted_price === null
                          ? 0
                          : row.discounted_price}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.category_id?.name}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.pharmacy_id?.name}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.offer_id?.name}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.in_stock === true ? "Available" : "Out of stock"}
                      </TableCell>
                      <TableCell>
                        <Box display="flex">
                          <Tooltip title="Edit">
                            <IconButton
                              disabled={role !== "A" ? true : false}
                              // component={Link}
                              // to={`/marketplace/products/editproduct`}
                              // state={{ data: row }}
                              onClick={() => {
                                navigate("/marketplace/products/editproduct", {
                                  state: { data: row },
                                  replace: true,
                                });
                              }}
                            >
                              <Create
                                sx={{
                                  color:
                                    role !== "A"
                                      ? colors.gray
                                      : colors.secondary,
                                }}
                              />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Delete">
                            <IconButton
                              disabled={role !== "A" ? true : false}
                              onClick={() => {
                                setProductId(row._id);
                                setConfirmDialog(!confirmDialog);
                              }}
                            >
                              <DeleteForever
                                sx={{
                                  color:
                                    role !== "A" ? colors.gray : colors.opal,
                                }}
                              />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Table sx={{ marginTop: "12px" }}>
              <TablePagination
                sx={{
                  borderBottom: "none",
                  bgcolor: colors.white,
                  borderRadius: "12px",
                }}
                rowsPerPageOptions={[10, 20, 30]}
                colSpan={12}
                count={+count}
                rowsPerPage={parseInt(searchParams.get("rows"))}
                page={parseInt(searchParams.get("page")) - 1}
                SelectProps={{
                  inputProps: {
                    "aria-label": "rows per page",
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </Table>
          </Fragment>
        )}
      </Box>
    </Fragment>
  );
}

export default Products;
