import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Close } from '@mui/icons-material';
import { Box, Dialog, DialogTitle, Grid, IconButton, InputLabel } from '@mui/material';
import { GoogleMap, MarkerF, useJsApiLoader } from '@react-google-maps/api';
import Geocode from "react-geocode";
import { CircleLoading } from '../UI/Loader';
import { ErrorToaster } from '../Toaster/Toaster';
import { InputField } from '../Fields/TextField';
import { PrimaryButton, SecondaryButton } from '../Button/Button';

const googleMapKey = 'AIzaSyCsT-b8-J4wnqKYUBFROMPQr_IEYdjNiSg'

function Map({ newAddress, defaultData }) {

  const autoCompleteRef = useRef(null);

  Geocode.setApiKey(googleMapKey);
  Geocode.setRegion("pk");

  // *For Map Positions
  const [center, setCenter] = useState({ lat: 0, lng: 0 });
  const [markerPosition, setMarkerPosition] = useState({ lat: 0, lng: 0 });

  // *For Address
  const [address, setAddress] = useState('');

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: googleMapKey,
    libraries: ['places'],
  });

  const containerStyle = {
    width: '100%',
    height: '200px',
    borderRadius: '10px',
    boxShadow: `rgba(149, 157, 165, 0.2) 0px 8px 24px`
  };

  const options = {
    disableDefaultUI: true,
    zoomControl: false,
    styles: [
      {
        featureType: 'poi',
        elementType: 'labels',
        stylers: [
          {
            visibility: 'off'
          }
        ]
      }
    ],
  };

  const handleMapLoad = (map) => {
    map.addListener('click', (e) => {
      Geocode.fromLatLng(e.latLng.lat(), e.latLng.lng()).then(
        (response) => {
          setMarkerPosition({ lat: e.latLng.lat(), lng: e.latLng.lng() });
          setAddress(response.results[0].formatted_address);
        },
        (error) => {
          console.error(error);
        }
      );
    });
  };

  const handleMarkerLoad = (marker) => {
    marker.addListener('dragend', (e) => {
      Geocode.fromLatLng(e.latLng.lat(), e.latLng.lng()).then(
        (response) => {
          setMarkerPosition({ lat: e.latLng.lat(), lng: e.latLng.lng() });
          setAddress(response.results[0].formatted_address);
        },
        (error) => {
          console.error(error);
        }
      );
    });
  };

  const handlePlaceSelect = (place) => {
    if (!place.geometry) {
      console.log('No location data received');
      return;
    }
    setCenter({
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng(),
    });
    setMarkerPosition({
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng(),
    });
    setAddress(place.formatted_address);
  }

  useEffect(() => {
    if (defaultData?.latitude !== 0) {
      setCenter({ lat: defaultData?.latitude, lng: defaultData?.longitude })
      setMarkerPosition({ lat: defaultData?.latitude, lng: defaultData?.longitude })
    } else if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        const { latitude, longitude } = position.coords;
        setCenter({ lat: latitude, lng: longitude });
        setMarkerPosition({ lat: latitude, lng: longitude });

        Geocode.fromLatLng(latitude, longitude).then(
          (response) => {
            setAddress(response.results[0].formatted_address);
          },
          (error) => {
            console.error(error);
          }
        );

      })
    }
  }, []);

  useEffect(() => {
    if (address) {
      let obj = {
        address: address,
        latitude: markerPosition?.lat,
        longitude: markerPosition?.lng,
      }
      newAddress(obj)
    }
  }, [address]);

  if (!isLoaded) return <CircleLoading />

  return (
    <Fragment>
      {/* <Autocomplete
        apiKey={googleMapKey}
        onPlaceSelected={(place) => console.log(place)}
        options={{
          types: ["(regions)"],
          componentRestrictions: { country: "ru" },
        }}
      /> */}
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={14}
        options={options}
        onLoad={handleMapLoad}
      >
        <MarkerF
          position={markerPosition}
          draggable={true}
          onDragEnd={(e) => {
            setMarkerPosition({ lat: e.latLng.lat(), lng: e.latLng.lng() });
          }}
          onLoad={handleMarkerLoad}
        />
      </GoogleMap>
    </Fragment>
  );
}

function AddressForm({ open, onClose, defaultData, save }) {

  const { register, handleSubmit, formState: { errors }, setValue, reset } = useForm();

  // *For Address Detail
  const [addressDetail, setAddressDetail] = useState();

  // *For Submit Form
  const submitForm = () => {
    try {
      save(addressDetail)
      reset()
    } catch (error) {
      ErrorToaster(error)
    }
  }

  useEffect(() => {
    if (defaultData?.latitude !== 0) {
      setValue('address', defaultData?.address)
    }
  }, [defaultData]);

  return (
    <Dialog
      maxWidth="xs"
      open={open}
      sx={{ '& .MuiDialog-paper': { width: '80%', height: "auto", borderRadius: 2, py: { xs: 1.5, md: 3 }, px: { xs: 1, md: 3 } } }}
    >
      <IconButton color="primary" onClick={() => { onClose(); reset() }} sx={{ position: 'absolute', right: 13, top: 13 }}>
        <Close />
      </IconButton>
      <DialogTitle sx={{ textAlign: "center", fontSize: '18px', fontWeight: 700 }}>Address</DialogTitle>
      <Box component="form" onSubmit={handleSubmit(submitForm)} >
        <Grid container spacing={1} alignItems={"center"}>
          <Grid item xs={12} sm={12}>
            <Map newAddress={(data) => { setAddressDetail(data); setValue('address', data?.address) }} defaultData={defaultData} />
          </Grid>
          <Grid item xs={12} sm={12}>
            <InputLabel>Location</InputLabel>
            <InputField
              disabled={true}
              placeholder={'location'}
              error={errors?.address?.message}
              register={register("address", {
                required: 'location',
              })}
            />
          </Grid>
          <Grid item xs={12} sm={12} sx={{ mt: 1 }}>
            <Box sx={{ display: 'flex' }}>
              <PrimaryButton
                title={"Continue"}
                type="submit"
              />
              <Box sx={{ mx: 0.5 }} />
              <SecondaryButton
                title="cancel"
                onClick={() => { onClose(); reset() }}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  )
}

export default AddressForm