import { get, post, patch, deleted } from "../../index";
import CategoriesRoutes from "./Categories.Routes";

const CategoriesServices = {
  getAllCategories: async (page, rowsPerPage) => {
    const data = await get(
      CategoriesRoutes.getAllcategories +
        `?page=${page}` +
        `&limit=${rowsPerPage}`
    );
    return data;
  },
  createcategory: async (obj) => {
    const data = await post(CategoriesRoutes.createcategory, obj);
    return data;
  },
  updatecategory: async (obj) => {
    const data = await patch(CategoriesRoutes.updateCategory, obj);
    return data;
  },
  deleteCategory: async (id) => {
    const data = await deleted(CategoriesRoutes.deletCategory + `?id=${id}`);
    return data;
  },
  uploadImage: async (obj) => {
    const data = await post(CategoriesRoutes.uploadImage, obj);
    return data;
  },
};

export default CategoriesServices;
