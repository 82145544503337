import React, { Fragment, useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { Layout } from './../../layout/Layout';
import BlogsServices from './../../apis/Blogs/BlogsServices';
import colors from './../../styles/colors';
import BreadCrumbs from './../../components/BreadCrumbs/BreadCrumbs';
import ConfirmationDialog from '../../components/Dialog/ConfirmationDialog';
//* Required Imports
import { Loading } from './../../components/UI/Loader';
import { getDate } from './../../utils/DateFormate';
import { SecondaryButton } from '../../components/Button/Button';
import { ErrorToaster, SuccessToaster } from '../../components/Toaster/Toaster';
import { baseUrl } from './../../../axios';
//* MUI Imports
import { useTheme } from '@emotion/react';
import { Create, DeleteForever, KeyboardArrowLeft, KeyboardArrowRight, } from '@mui/icons-material';
import { Paper, Box, Grid, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, IconButton, Avatar, Tooltip } from '@mui/material';

// Table Pagination ActionsComponent
function TablePaginationActions(props) {

  const theme = useTheme

  const { count, page, rowsPerPage, onPageChange } = props;

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  useEffect(() => {
    window.scrollTo({ top: 0 })
  }, [])

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
    </Box>
  );
}

const tableHead = ['Title', 'Arabic Title', ' Short Description', 'Arabic Short Description', 'Picture', 'Added on', 'Action']
const BlogsList = () => {

  //* For Handle Pagination
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getAllBlogs(newPage + 1, rowsPerPage)
  };

  const handleChangeRowsPerPage = (event) => {
    // setRowsPerPage(parseInt(event.target.value, 2));
    setRowsPerPage(Math.floor(event.target.value))
    setPage(0);
    getAllBlogs(page + 1, event.target.value)
  };

  // *For Loader
  const [loader, setLoader] = useState(true);
  const [confirmDialog, setConfirmDialog] = useState(false)
  const [blogId, setBlogId] = useState('')
  const [count, setCount] = useState('')
  //* For Blogs List
  const navigate = useNavigate()
  const [allBlogs, setAllBlogs] = useState([]);
  const getAllBlogs = async (newPage, rowsPerPage) => {
    try {
      const { data } = await BlogsServices.getAllBlogs(newPage ? newPage : 1, rowsPerPage ? rowsPerPage : "")
      if (data === null) return
      setAllBlogs(data.result)
      setCount(data.count)
    }
    catch (error) {
      ErrorToaster(error)
    }
    finally {
      setLoader(false)
    }
  }

  const blogDelete = async () => {
    try {
      const { data } = await BlogsServices.deleteBlog(blogId)
      if (data === null) return
      SuccessToaster("Blog Deleted")
      getAllBlogs()
    }
    catch (error) {
      ErrorToaster(error)
    }
  }

  useEffect(() => {
    getAllBlogs()
  }, [])
  return (
    <Fragment>
      <ConfirmationDialog open={confirmDialog} onClose={() => setConfirmDialog(!confirmDialog)} action={blogDelete} title={"Delete This Blog"} />
      <Box sx={{
        backgroundColor: colors.lightGray, p: 3, borderRadius: "12px", width: "calc(100vw - 370px)"
      }} >
        <Grid container alignItems="center" justifyContent="space-between"
          sx={{
            bgcolor: colors.white, p: 2, borderRadius: "12px",
          }}
        >
          <Grid item xs={6}>
            <Typography variant="body1" fontWeight="bold" color="initial">Blogs List</Typography>
          </Grid>
          <Grid item xs={6} sx={{ display: "flex", justifyContent: "flex-end" }}>
            <BreadCrumbs currentPage={['Blogs']} />
          </Grid>
        </Grid>
        {loader ? (
          <Box sx={{ width: 1, textAlign: "center", pt: 5 }}>
            <Loading />
          </Box>
        ) : (
          <Fragment>
            <Box sx={{ display: "flex", justifyContent: "flex-end", bgcolor: colors.white, p: 2, borderRadius: "12px", textAlign: "right", mt: 2 }}>
              <SecondaryButton title="Create Blog" onClick={() => navigate('/create-blog')} />
            </Box>
            <TableContainer component={Paper}
              sx={{
                '&::-webkit-scrollbar': {
                  bgcolor: colors.white,
                  height: "8px",
                  borderRadius: "10px",
                  cursor: "pointer"
                },
                '&::-webkit-scrollbar-thumb': {
                  bgcolor: colors.primary,
                  borderRadius: "10px",
                  cursor: "pointer"
                },
                "&.MuiPaper-root": {
                  borderRadius: "12px",
                  mt: 2,
                  boxShadow: 0,
                }
              }}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead >
                  <TableRow sx={{ bgcolor: colors.lightGray }}>
                    {tableHead.map((item, index) =>
                      <TableCell key={index} sx={{ fontWeight: "bold" }} >{item}</TableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {allBlogs.map((row, index) => (
                    <TableRow
                      key={index}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                      >
                        {row.title}
                      </TableCell>
                      <TableCell >
                        <Typography variant="body2" color="initial" noWrap>{row.title_ar ?? "-"}</Typography>
                      </TableCell>
                      <TableCell
                      >
                        <Tooltip title={row.short_description}>
                          <Typography variant="body2" color="initial" noWrap
                            sx={{
                              textOverflow: "ellipsis", minWidth: "300px",
                              overflow: "hidden", whiteSpace: "nowrap", maxWidth: "350px"
                            }}
                          >{row.short_description}</Typography>
                        </Tooltip>
                      </TableCell>
                      <TableCell>
                        <Tooltip title={row.short_description_ar ?? "-"}>
                          <Typography variant="body2" color="initial" noWrap
                            sx={{
                              textOverflow: "ellipsis", minWidth: "300px",
                              overflow: "hidden", whiteSpace: "nowrap", maxWidth: "350px"
                            }}
                          >{row.short_description_ar ?? "-"}</Typography>
                        </Tooltip>
                      </TableCell>
                      <TableCell>
                        <Avatar src={baseUrl + row.image} variant="rounded" />
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2" color="initial" noWrap>{getDate(row.createdAt)}</Typography>
                      </TableCell>

                      <TableCell>
                        <Box display="flex">
                          <Tooltip title="Edit">
                            <IconButton component={Link} to={`/edit-blog`} state={{ data: row }}>
                              <Create sx={{ color: colors.secondary }} />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Delete">
                            <IconButton onClick={() => { setBlogId(row._id); setConfirmDialog(!confirmDialog) }}>
                              <DeleteForever sx={{ color: colors.opal }} />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}

                </TableBody>
              </Table>
            </TableContainer>
            <Table sx={{ marginTop: "12px" }} >
              <TablePagination
                sx={{ borderBottom: "none", bgcolor: colors.white, borderRadius: "12px" }}
                rowsPerPageOptions={[10, 20, 30]}
                colSpan={12}
                count={+count}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    'aria-label': 'rows per page',
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </Table>
          </Fragment>
        )}
      </Box>
    </Fragment>
  )
}

export default BlogsList