const ProductsRoutes = {
  getAllProducts: "/marketplace/products/",
  createProduct: "/marketplace/products/create",
  updateProduct: "/marketplace/products/update",
  deleteProduct: "/marketplace/products/delete",
  uploadImage: "/marketplace/products/upload",
  createBulk: "/marketplace/products/create/bulk",
};

export default ProductsRoutes;
