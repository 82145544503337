import { baseUrl } from "../../../axios";
//* Import Swiper styles
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
//* import required modules
import { Navigation } from "swiper";
import { Loading } from "./../UI/Loader";
import { useState, Fragment } from "react";
//* Mui Imports
import { Close } from "@mui/icons-material";
import { Box, Dialog, DialogTitle, IconButton, Grid, Typography } from "@mui/material";
import Images from "../../assets/Images";

function UserIdCardDialog({ title, open, onClose, image, user }) {

  return (
    <Dialog
      // data-testId="dialog-box"
      maxWidth="xs"
      open={open}
      sx={{
        "& .MuiDialog-paper": {
          width: "80%",
          height: "auto",
          borderRadius: 2,
          py: { xs: 2, md: 4 },
          px: { xs: 3, md: 6 },
        },
      }}
    >
      <IconButton data-testId="close-dialog" color="primary" onClick={() => onClose()} sx={{ position: "absolute", right: 13, top: 13 }}>
        <Close />
      </IconButton>
      <DialogTitle sx={{ textAlign: "center", fontSize: "18px", fontWeight: 700 }}>{title}</DialogTitle>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12}>
          <Swiper navigation={true} modules={[Navigation]}>
            {image.map((item, index) => (
              <Fragment key={index}>
                {item?.substring(7, 11) === "temp" ? (
                  <SwiperSlide key={index} style={{ display: "flex", justifyContent: "center" }}>
                    {/* <Typography>Issue in uploaded Image </Typography> */}
                    <img src={baseUrl + item.replace("temp", user)} alt="ID Card" style={{ width: "100%", objectFit: "cover" }} />
                  </SwiperSlide>
                ) : item === "" || item === null ? (
                  <SwiperSlide key={index} style={{ display: "flex", justifyContent: "center" }}>
                    <img src={Images.notFound} alt="NoImgfound" style={{ width: "100%", objectFit: "cover" }} />
                  </SwiperSlide>
                ) : (
                  <SwiperSlide key={index} style={{ display: "flex", justifyContent: "center" }}>
                    <img src={baseUrl + item} alt="ID Card" style={{ width: "100%", objectFit: "cover" }} />
                  </SwiperSlide>
                )}
              </Fragment>
            ))}
          </Swiper>
        </Grid>
      </Grid>
    </Dialog>
  );
}

export default UserIdCardDialog;
