import React, { useEffect } from "react";
import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
} from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material";
import colors from "./styles/colors";
import AdminRoutes from "./routes/admin.routes";
import useAuth from "./hooks/useAuth";
import { ToasterContainer } from "./components/Toaster/Toaster";
import PublicRoutes from "./routes/routes";
import { Layout } from "./layout/Layout";

const theme = createTheme({
  palette: {
    primary: {
      main: colors.primary,
      contrastText: "#fff",
    },
    secondary: {
      main: colors.secondary,
      contrastText: "#fff",
    },
  },
  typography: {
    fontFamily: ["Roboto", "sans-serif"].join(","),
  },
});

function App() {
  const { user } = useAuth();

  return (
    <ThemeProvider theme={theme}>
      <ToasterContainer />
      <BrowserRouter>
        <Routes>
          <Route element={user ? <Layout /> : <Navigate to="/login" />}>
            {AdminRoutes.map((route, index) => (
              <Route key={index} path={route.path} element={route.component} />
            ))}
          </Route>
          <Route element={user ? <Navigate to="/" /> : <Outlet />}>
            {PublicRoutes.map((route, index) => (
              <Route key={index} path={route.path} element={route.component} />
            ))}
          </Route>
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
