import React, { Fragment, useState, useEffect } from 'react'
import colors from './../../styles/colors';
import OrderServices from '../../apis/Order/OrderServices';
import { ErrorToaster } from './../Toaster/Toaster';
import { Loading } from './../UI/Loader';
//* MUI Imports
import { Box, Typography, Divider } from '@mui/material';
import MedicationIcon from '@mui/icons-material/Medication';

const RecentOrdersCard = () => {
  const [rowsData, setRowsData] = useState([]);
  // console.log("🚀 ~ file: RecentOrdersCard.js:11 ~ RecentOrdersCard ~ rowsData:", rowsData)
  // *For Loader
  const [loader, setLoader] = useState(true);
  const allOrders = async () => {
    try {
      const { data } = await OrderServices.getAllOrders("", "", "", "", "", "", 1, "")
      if (data === null) return
      setRowsData(data.result)
    }
    catch (error) {
      ErrorToaster(error)
    }
    finally {
      setLoader(false)
    }
  }
  //* for last 4 orders
  const findLast = rowsData?.slice(0, 4)
  useEffect(() => {
    allOrders()
  }, [])
  return (
    <Box sx={{
      backgroundColor: colors.white, py: 4, px: 3, my: 2, borderRadius: "12px", minHeight: "320px", maxHeight: "320px"
    }}>
      {loader ? (
        <Box sx={{ width: 1, textAlign: "center", pt: 5 }}>
          <Loading />
        </Box>
      ) : (
        <Fragment>
          <Box
            sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", }}
          >
            <Typography sx={{ fontWeight: "bold", mb: 2 }}>
              Recent Orders
            </Typography>
          </Box>
          {findLast.map((item, index) => (
            <Box key={index}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box>
                  <Typography
                    sx={{ mr: 2, fontSize: "16px", fontWeight: "bold", color: colors.ebonyClay }}
                  >
                    {item.order_by}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    sx={{ fontSize: "13px", color: colors.ebonyClay, mr: 2 }}
                  >
                    Order No: {item.order_id}
                  </Typography>
                  <MedicationIcon
                    sx={{
                      color: colors.primary, backgroundColor: colors.bg2,
                      p: 0.5, borderRadius: "6px", fontSize: "16px",
                    }}
                  />
                </Box>
              </Box>
              <Divider
                sx={{
                  color: colors.textSecondary, width: "100%", my: 2,
                }}
              />
            </Box>
          ))}
        </Fragment>
      )}
    </Box>
  )
}

export default RecentOrdersCard