import { get, post, patch, deleted } from '../index'
import ContentRoutes from './Content.Routes';

const ContentServices = {

  aboutUsContent: async () => {
    const data = await get(ContentRoutes.aboutUsContent);
    return data
  },
  termsContent: async () => {
    const data = await get(ContentRoutes.termsContent);
    return data
  },
  privacy: async () => {
    const data = await get(ContentRoutes.privacy);
    return data
  },
  createContent: async (id, obj) => {
    const data = await patch(ContentRoutes.createContent + `?id=${id}`, obj);
    return data
  },

}

export default ContentServices