import React, { Fragment, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
//* Required Components
import { Layout } from "../../layout/Layout";
import BreadCrumbs from "../../components/BreadCrumbs/BreadCrumbs";
import ConfirmationDialog from "../../components/Dialog/ConfirmationDialog";
import VoucherServices from "../../apis/Voucher/VoucherServices";
import { Loading } from "../../components/UI/Loader";
import { getDate } from "../../utils/DateFormate";
import { SecondaryButton } from "../../components/Button/Button";
import { ErrorToaster, SuccessToaster } from "../../components/Toaster/Toaster";
import colors from "../../styles/colors";
//* MUI Imports
import { useTheme } from "@emotion/react";
import {
  KeyboardArrowLeft,
  KeyboardArrowRight,
  Create,
  DeleteForever,
  Visibility,
} from "@mui/icons-material";
import {
  Paper,
  Box,
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  IconButton,
} from "@mui/material";
import PharmacyServices from "../../apis/Pharmacy/PharmacyServices";
import AllocationDialog from "../../components/Dialog/AllocationDialog";
import moment from "moment";
import UserInfoDialog from "../../components/Dialog/UserInfoDialog";

//* Table Pagination ActionsComponent
function TablePaginationActions(props) {
  const theme = useTheme;

  const { count, page, rowsPerPage, onPageChange } = props;

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
    </Box>
  );
}

const tableHead = [
  "Order Id",
  "Allocated At",
  "Order Type",
  "Total message",
  "Documents",
  "Action",
];

const AllocatedPharmacies = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [allocateDialog, setAllocateDialog] = useState(false);

  // *For Loader
  const [loader, setLoader] = useState(true);
  const [pharmacies, setPharmacies] = useState([]);
  const [allocatePharmacies, setAllocatePharmacies] = useState();
  const [count, setCount] = useState("");
  const [documents, setDocuments] = useState();
  const [docDialog, setDocDialog] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getAllocatedPharmacy(newPage + 1, rowsPerPage);
  };

  const handleChangeRowsPerPage = (event) => {
    // setRowsPerPage(parseInt(event.target.value, 10));
    setRowsPerPage(Math.floor(event.target.value));
    setPage(0);
    getAllocatedPharmacy(page + 1, event.target.value);
  };

  //* Get All Pharmacy
  const getAllocatedPharmacy = async (newPage, rowsPerPage) => {
    try {
      const { data, responseCode, status } =
        await PharmacyServices.getAllocatedPharmacy(
          state?.id,
          newPage ? newPage : 1,
          rowsPerPage ? rowsPerPage : ""
        );
      if (status === true && responseCode === 200) {
        setAllocatePharmacies(data.allocations);
        setCount(data.totalCount);
      }
    } catch (error) {
      ErrorToaster(error);
    } finally {
      setLoader(false);
    }
  };

  //* Get All Pharmacy
  const getPharmacyList = async () => {
    try {
      const { data, responseCode, status } =
        await PharmacyServices.getPharmacyList(state?.id);
      if (status === true && responseCode === 200) {
        setPharmacies(data.result);
      }
    } catch (error) {
      ErrorToaster(error);
    }
  };

  const handleAllocateOrder = async (pharmacyData) => {
    try {
      let obj = {
        order_id: state.id,
        pharmacies: pharmacyData,
      };
      const { message } = await PharmacyServices.allocateOrder(obj);
      SuccessToaster(message);
      setAllocateDialog(false);
      getAllocatedPharmacy();
    } catch (error) {
      console.log(
        "file: allocated-pharmacies.js:124 => handleAllocateOrder => error:",
        error
      );
    }
  };

  const handleViewDoc = (doc) => {
    try {
      setDocuments(doc);
      setDocDialog(true);
    } catch (error) {
      console.log("file: Order.js:127 => handleViewDoc => error:", error);
    }
  };

  useEffect(() => {
    getAllocatedPharmacy();
    getPharmacyList();
  }, []);
  return (
    <Fragment>
      <UserInfoDialog
        open={docDialog}
        onClose={() => setDocDialog(false)}
        userInfo={documents}
      />
      <AllocationDialog
        open={allocateDialog}
        onClose={() => setAllocateDialog(false)}
        data={pharmacies}
        onSave={(data) => handleAllocateOrder(data)}
      />

      <Box
        sx={{
          backgroundColor: colors.lightGray,
          p: 3,
          borderRadius: "12px",
          width: "calc(100vw - 370px)",
        }}
      >
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          sx={{
            bgcolor: colors.white,
            p: 2,
            borderRadius: "12px",
          }}
        >
          <Grid item xs={6}>
            <Typography variant="body1" fontWeight="bold" color="initial">
              Allocated Pharmacies
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <BreadCrumbs currentPage={["Allocated Pharmacies"]} />
          </Grid>
        </Grid>
        {loader ? (
          <Box sx={{ width: 1, textAlign: "center", pt: 5 }}>
            <Loading />
          </Box>
        ) : (
          <Fragment>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                bgcolor: colors.white,
                p: 2,
                borderRadius: "12px",
                textAlign: "right",
                mt: 2,
              }}
            >
              <SecondaryButton
                title="Allocate Pharmacy"
                onClick={() => setAllocateDialog(true)}
              />
            </Box>
            <TableContainer
              component={Paper}
              sx={{
                "&::-webkit-scrollbar": {
                  bgcolor: colors.white,
                  height: "8px",
                  borderRadius: "10px",
                  cursor: "pointer",
                },
                "&::-webkit-scrollbar-thumb": {
                  bgcolor: colors.primary,
                  borderRadius: "10px",
                  cursor: "pointer",
                },
                "&.MuiPaper-root": {
                  borderRadius: "12px",
                  mt: 2,
                  boxShadow: 0,
                },
              }}
            >
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow sx={{ bgcolor: colors.lightGray }}>
                    {tableHead.map((item, index) => (
                      <TableCell key={index} sx={{ fontWeight: "bold" }}>
                        {item}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {allocatePharmacies.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row">
                        {row.order?.order_id}
                      </TableCell>
                      <TableCell>
                        {moment(row.createdAt).format("DD/MM/YYYY hh:mm a")}
                      </TableCell>
                      <TableCell>
                        {row.order?.medication.length > 0
                          ? "Medication"
                          : "Prescription"}
                      </TableCell>
                      <TableCell>{row.messageCount}</TableCell>
                      <TableCell>
                        <SecondaryButton
                          title="View Doc"
                          onClick={() => handleViewDoc(row?.order?.user)}
                        />
                      </TableCell>
                      <TableCell>
                        <Tooltip title="View">
                          <IconButton
                            onClick={() =>
                              navigate(`/allocation-detail/${row._id}`)
                            }
                          >
                            <Visibility sx={{ color: colors.completed }} />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Table sx={{ marginTop: "12px" }}>
              <TablePagination
                sx={{
                  borderBottom: "none",
                  bgcolor: colors.white,
                  borderRadius: "12px",
                }}
                rowsPerPageOptions={[10, 20, 30]}
                colSpan={12}
                count={+count}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    "aria-label": "rows per page",
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </Table>
          </Fragment>
        )}
      </Box>
    </Fragment>
  );
};

export default AllocatedPharmacies;
