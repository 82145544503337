import React, { Fragment } from "react";
import {
  Box,
  CardMedia,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { Layout } from "../../layout/Layout";
import colors from "./../../styles/colors";
import BreadCrumbs from "./../../components/BreadCrumbs/BreadCrumbs";
import TextEditor from "../../components/TextEditor/TextEditor";
import { InputField } from "./../../components/Fields/TextField";
import { PrimaryButton } from "./../../components/Button/Button";
import { useForm } from "react-hook-form";
import { useState } from "react";
import BlogsServices from "./../../apis/Blogs/BlogsServices";
import { SuccessToaster } from "../../components/Toaster/Toaster";
import { ErrorToaster } from "./../../components/Toaster/Toaster";
import { useNavigate } from "react-router-dom";
import { Close, CloudUpload } from "@mui/icons-material";
import { baseUrl } from "../../../axios";

const CreateBlog = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const [content, setContent] = useState("");
  const [contentAr, setContentAr] = useState("");
  const [imgPath, setImgPath] = useState("");
  const [blogImg, setBlogImg] = useState("");
  const [fileInputKey, setFileInputKey] = useState(Date.now());
  const getTextValue = (value) => {
    setContent(value);
  };
  const getTextArValue = (value) => {
    setContentAr(value);
  };
  const blogSubmit = async (formData) => {
    try {
      const obj = {
        title: formData.title,
        title_ar: formData.titleAr,
        short_description: formData.desc,
        short_description_ar: formData.descAr,
        content: content,
        content_ar: contentAr,
        image: imgPath,
      };
      const { data, message } = await BlogsServices.createBlog(obj);
      if (data === null) return;
      SuccessToaster(message);
      reset();
      setTimeout(() => navigate("/blogs"), 1000);
    } catch (error) {
      ErrorToaster(error);
    }
  };
  const blogPicture = async (image) => {
    const validImageTypes = ["image/png", "image/jpeg", "image/jpg"];
    if (!validImageTypes.includes(image?.type)) {
      ErrorToaster("Please upload a valid image file (PNG, JPEG, or JPG)");
    } else {
      try {
        let formData = new FormData();
        formData.append("imagePath", image);
        const { data } = await BlogsServices.blogPicture(formData);
        if (data === null) return;
        setImgPath(data.imgPath);
        setBlogImg(data.imgPath);
        setFileInputKey(Date.now());
      } catch (error) {
        ErrorToaster(error);
      }
    }
  };
  return (
    <Fragment>
      <Box
        sx={{
          backgroundColor: colors.lightGray,
          p: 3,
          borderRadius: "12px",
          width: "calc(100vw - 370px)",
        }}
      >
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          sx={{ bgcolor: colors.white, p: 2, borderRadius: "12px" }}
        >
          <Grid item xs={6}>
            <Typography variant="body1" fontWeight="bold" color="initial">
              Create Blog
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <BreadCrumbs currentPage={["Blogs", "Create Blog"]} />
          </Grid>
        </Grid>

        <Typography component={"form"} onSubmit={handleSubmit(blogSubmit)}>
          <Box
            sx={{ bgcolor: colors.white, p: 3, borderRadius: "12px", mt: 2 }}
          >
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <InputField
                  label="Title"
                  register={register("title", {
                    required: "Please fill all fields",
                    // onChange: () => setEnableBtn(true),
                    pattern: {
                      message: "Please fill all fields",
                    },
                  })}
                  error={errors?.title?.message}
                />
              </Grid>
              <Grid item xs={6}>
                <InputField
                  label="Arabic Title"
                  register={register("titleAr", {
                    required: "Please fill all fields",
                    // onChange: () => setEnableBtn(true),
                    pattern: {
                      message: "Please fill all fields",
                    },
                  })}
                  error={errors?.titleAr?.message}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  sx={{ width: "100%", mb: 2 }}
                  multiline
                  rows={3}
                  label=" Short Description"
                  {...register("desc", {
                    required: "Please fill all fields",
                    // onChange: () => setEnableBtn(true),
                    pattern: {
                      message: "Please fill all fields",
                    },
                  })}
                  error={errors?.desc}
                  helperText={errors?.desc?.message}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  sx={{ width: "100%", mb: 2 }}
                  multiline
                  rows={3}
                  label="Short Arabic Description"
                  {...register("descAr", {
                    required: "Please fill all fields",
                    // onChange: () => setEnableBtn(true),
                    pattern: {
                      message: "Please fill all fields",
                    },
                  })}
                  error={errors?.descAr}
                  helperText={errors?.descAr?.message}
                />
              </Grid>
              <Grid item xs={12} display="flex" justifyContent="center">
                <Box>
                  <Typography>Content</Typography>
                  <TextEditor getValue={getTextValue} />
                </Box>
              </Grid>
              <Grid item xs={12} display="flex" justifyContent="center">
                <Box>
                  <Typography>Arabic Content</Typography>
                  <TextEditor getValue={getTextArValue} type={true} />
                </Box>
              </Grid>
              <Grid item xs={12}>
                {blogImg !== "" && (
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Box sx={{ flexShrink: 0, position: "relative" }}>
                      <CardMedia
                        component={"img"}
                        image={baseUrl + blogImg}
                        sx={{
                          height: "150px",
                          width: "150px",
                          objectFit: "contain",
                        }}
                      />
                      <IconButton
                        component="span"
                        disableRipple
                        sx={{
                          position: "absolute",
                          right: "-6px",
                          top: "-6px",
                          padding: "1px",
                        }}
                        onClick={() => setBlogImg("")}
                      >
                        <Close sx={{ color: colors.danger }} />
                      </IconButton>
                    </Box>
                  </Box>
                )}
                <Box
                  sx={{
                    display: blogImg ? "none" : "flex",
                    gap: "10px",
                    justifyContent: "center",
                  }}
                >
                  <span
                    className="btn btn-primary btn-file"
                    style={{
                      position: "relative",
                      overflow: "hidden",
                      color: "white",
                      height: "50px",
                      width: "fit-content",
                      padding: "12px",
                      borderRadius: "5px",
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <CloudUpload
                      sx={{
                        fontSize: "60px",
                        color: colors.primary,
                        cursor: "pointer",
                        "&:hover": {
                          color: colors.secondary,
                          transition: "0.3s ease-in-out",
                        },
                      }}
                    />
                    <input
                      // multiple
                      type="file"
                      key={fileInputKey}
                      style={{
                        position: " absolute",
                        filter: "alpha(opacity=0)",
                        top: "0",
                        right: "0",
                        align: "right",
                        minWidth: "100%",
                        minHeight: "100%",
                        fontSize: " 100px",
                        opacity: "0",
                        outline: "none",
                        background: "white",
                        cursor: "inherit",
                        display: "block",
                      }}
                      onChange={(event) => {
                        blogPicture(event.target.files[0]);
                      }}
                    />
                  </span>
                  {/* {
                      image !== ""
                    }
                    <Button >Clear Image</Button> */}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{ fontSize: "11px", color: colors.ebonyClay }}
                  >
                    Upload Image
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <Box
              sx={{
                mt: 2,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <PrimaryButton
                data-testId="save-btn"
                title={"Create Blog"}
                type="submit"
              // loading={loading}
              />
            </Box>
          </Box>
        </Typography>
      </Box>
    </Fragment>
  );
};

export default CreateBlog;
