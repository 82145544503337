import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import BreadCrumbs from "./../../components/BreadCrumbs/BreadCrumbs";
import { Layout } from "./../../layout/Layout";
import OrderServices from "../../apis/Order/OrderServices";
import QuoteDialog from "../../components/Dialog/QuoteDialog";
import DataFilter from "./../../components/Filter/DataFilter";
import ConfirmationDialog from "../../components/Dialog/ConfirmationDialog";
import NotificationDialog from "../../components/Dialog/NotificationDialog";
//* Required Components
import {
  ErrorToaster,
  SuccessToaster,
} from "./../../components/Toaster/Toaster";
import colors from "../../styles/colors";
import { getTimeDate } from "../../utils/DateFormate";
import { Loading } from "./../../components/UI/Loader";
//* MUI Imports
import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import {
  Recommend,
  RequestQuote,
  Done,
  Visibility,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  NotificationImportant,
} from "@mui/icons-material";
import {
  Paper,
  Box,
  Grid,
  Typography,
  tooltipClasses,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  IconButton,
} from "@mui/material";
//* Firebase import
import { onMessageListener } from "../../firebase/firebase";

//* Table Pagination ActionsComponent
function TablePaginationActions(props) {
  const theme = useTheme;

  const { count, page, rowsPerPage, onPageChange } = props;

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
    </Box>
  );
}

//* custom tooltip
const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 200,
    textAlign: "center",
    background: "#0D253C",
    "&>.MuiTooltip-arrow": {
      "&:before": {
        color: "#0D253C",
      },
    },
  },
});

const tableHead = [
  "Order Id",
  "Order Type",
  "Order By",
  "Placed On",
  "Payment Method",
  "Status",
  "Action",
  "Allocate",
  "Detail",
];
const Order = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [orderId, setOrderId] = useState("");
  const [count, setCount] = useState("");
  const [rowsData, setRowsData] = useState([]);
  const [quoteDialog, setQuoteDialog] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [notificationDialog, setNotificationDialog] = useState(false);
  const [checkStatus, setCheckStatus] = useState("");
  const [checkName, setCheckName] = useState("");
  const [checkType, setCheckType] = useState("");
  const [checkOrderId, setCheckOrderId] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const role = localStorage.getItem("role");
  // *For Loader
  const [loader, setLoader] = useState(true);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    allOrders(
      checkStatus ? checkStatus : "",
      checkOrderId ? checkOrderId : "",
      checkName ? checkName : "",
      checkType ? checkType : "",
      fromDate ? fromDate : "",
      toDate ? toDate : "",
      newPage + 1,
      rowsPerPage
    );
  };

  const handleChangeRowsPerPage = (event) => {
    // setRowsPerPage(parseInt(event.target.value, 2));
    setRowsPerPage(Math.floor(event.target.value));
    setPage(0);
    allOrders(
      checkStatus ? checkStatus : "",
      checkOrderId ? checkOrderId : "",
      checkName ? checkName : "",
      checkType ? checkType : "",
      fromDate ? fromDate : "",
      toDate ? toDate : "",
      1,
      event.target.value
    );
  };

  const [status, setStatus] = useState([]);

  // *For Get Status ID's
  const sortByStatus = (stat, id, name, type, fromDate, toDate) => {
    setCheckStatus(stat);
    setCheckOrderId(id);
    setCheckName(name);
    setCheckType(type);
    setFromDate(fromDate);
    setToDate(toDate);
    setRowsPerPage(10);
    setPage(0);
    allOrders(
      stat ? stat : "",
      id ? id : "",
      name ? name : "",
      type ? type : "",
      fromDate ? fromDate : "",
      toDate ? toDate : ""
    );
  };

  const orderStatus = async () => {
    try {
      const { data } = await OrderServices.orderStatus();
      if (data === null) return;
      setStatus(data.result);
    } catch (error) {
      // ErrorToaster(error)
    }
  };

  //* All Orders API Call
  const allOrders = async (
    stat,
    id,
    name,
    type,
    from,
    to,
    newPage,
    rowsPerPage
  ) => {
    setLoader(true);
    try {
      const { data } = await OrderServices.getAllOrders(
        stat ? stat : "",
        id ? id : "",
        name ? name : "",
        type ? type : "",
        from ? from : "",
        to ? to : "",
        newPage ? newPage : 1,
        rowsPerPage ? rowsPerPage : ""
      );
      if (data === null) return;
      setRowsData(data.result);
      setCount(data.count);
    } catch (error) {
      ErrorToaster(error);
    } finally {
      setLoader(false);
    }
  };
  //* Handle Quote order api
  const quoteOrder = async (quote, reset) => {
    try {
      const obj = {
        order_id: orderId,
        total_amount: quote.price,
        message: quote.message,
      };
      const { status, message, responseCode } = await OrderServices.quoteOrder(
        obj
      );
      if (status === true && responseCode === 200) {
        SuccessToaster(message);
        setQuoteDialog(!quoteDialog);
        reset();
        allOrders();
      }
    } catch (error) {
      ErrorToaster(error);
    }
  };
  //* Handle order Complete Api
  const orderComplete = async () => {
    try {
      const { data } = await OrderServices.completeOrder(orderId);
      if (data === null) return;
      SuccessToaster("Order Completed");
      allOrders();
    } catch (error) {
      ErrorToaster(error);
    }
  };

  useEffect(() => {
    allOrders();
    orderStatus();
  }, []);

  //* FireBase Message Listner for reload page
  onMessageListener()
    .then((payload) => {
      allOrders();
      orderStatus();
    })
    .catch((err) => console.log("failed: ", err));

  return (
    <Fragment>
      <ConfirmationDialog
        open={confirmDialog}
        onClose={() => setConfirmDialog(!confirmDialog)}
        action={orderComplete}
        title={"Mark This Order Complete"}
        btn={"Completed"}
      />
      <QuoteDialog
        open={quoteDialog}
        onClose={() => setQuoteDialog(!quoteDialog)}
        title={"Quote Offer"}
        action={quoteOrder}
        loading={""}
      />
      <NotificationDialog
        open={notificationDialog}
        onClose={() => setNotificationDialog(!notificationDialog)}
        id={orderId}
        title={"Send Notifications"}
      />
      <Box
        sx={{
          backgroundColor: colors.lightGray,
          p: 3,
          borderRadius: "12px",
          width: "calc(100vw - 370px)",
        }}
      >
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          sx={{
            bgcolor: colors.white,
            p: 2,
            borderRadius: "12px",
          }}
        >
          <Grid item xs={6}>
            <Typography variant="body1" fontWeight="bold" color="initial">
              Orders List
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <BreadCrumbs currentPage={["Orders"]} />
          </Grid>
        </Grid>
        {loader ? (
          <Box sx={{ width: 1, textAlign: "center", pt: 5 }}>
            <Loading />
          </Box>
        ) : (
          <Fragment>
            <Box>
              <DataFilter
                sortData={(
                  stat,
                  id,
                  name,
                  type,
                  fromDate,
                  toDate,
                  page,
                  rows
                ) =>
                  sortByStatus(
                    stat,
                    id,
                    name,
                    type,
                    fromDate,
                    toDate,
                    page,
                    rows
                  )
                }
                data={status?.map((item) => {
                  return item;
                })}
                type={"order"}
                page={page}
                rows={rowsPerPage}
              />
            </Box>
            <TableContainer
              component={Paper}
              sx={{
                "&::-webkit-scrollbar": {
                  bgcolor: colors.white,
                  height: "8px",
                  borderRadius: "10px",
                  cursor: "pointer",
                },
                "&::-webkit-scrollbar-thumb": {
                  bgcolor: colors.primary,
                  borderRadius: "10px",
                  cursor: "pointer",
                },
                "&.MuiPaper-root": {
                  borderRadius: "12px",
                  mt: 2,
                  boxShadow: 0,
                },
              }}
            >
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow sx={{ bgcolor: colors.lightGray }}>
                    {tableHead.map((item, index) => (
                      <TableCell key={index} sx={{ fontWeight: "bold" }}>
                        {item}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rowsData.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row">
                        {row.order_id}
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2" color="initial" noWrap>
                          {row?.medication?.length
                            ? "Medication"
                            : "Prescription"}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">
                          <b>Name: </b>
                          {row?.order_by ?? "--"}
                        </Typography>
                        <Typography variant="body2" noWrap>
                          <b>Email: </b>
                          {row.deleted_email
                            ? row.deleted_email
                            : row.order_by_email}
                        </Typography>
                        <Typography variant="body2">
                          <b>Phone: </b>
                          {row.deleted_phone
                            ? row.deleted_phone
                            : row.user_phone}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2" color="initial" noWrap>
                          {getTimeDate(row.date_time)}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>
                          {row?.payment?.payment_method ?? "-"}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <CustomTooltip title={row.status.ar} arrow>
                          <Typography
                            variant="body2"
                            color="initial"
                            noWrap
                            sx={{
                              cursor: "default",
                              maxWidth: 170,
                              color:
                                row.status.name === "Requested"
                                  ? colors.notification
                                  : row.status.name === "Quoted"
                                  ? colors.black
                                  : row.status.name === "Accepted"
                                  ? colors.secondary
                                  : row.status.name === "Confirmed"
                                  ? colors.primary
                                  : row.status.name === "Completed"
                                  ? colors.completed
                                  : colors.danger,
                            }}
                          >
                            {row.status.name}
                          </Typography>
                        </CustomTooltip>
                      </TableCell>
                      <TableCell>
                        {row.status.name === "Cancelled" ? (
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Tooltip title="Quote">
                              <IconButton
                                onClick={() => {
                                  setQuoteDialog(!quoteDialog);
                                  setOrderId(row?._id);
                                }}
                              >
                                <RequestQuote
                                  sx={{
                                    color: colors.secondary,
                                    fontSize: "20px",
                                  }}
                                />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        ) : row.status.name === "Requested" ||
                          row.status.name === "Quoted" ? (
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Tooltip title="Quote">
                              <IconButton
                                onClick={() => {
                                  setQuoteDialog(!quoteDialog);
                                  setOrderId(row?._id);
                                }}
                              >
                                <RequestQuote
                                  sx={{
                                    color: colors.secondary,
                                    fontSize: "20px",
                                  }}
                                />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        ) : row.status.name === "Confirmed" ? (
                          <Box sx={{ display: "flex" }}>
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <Tooltip title="Notification">
                                <IconButton
                                  onClick={() => {
                                    setNotificationDialog(!notificationDialog);
                                    setOrderId(row?._id);
                                  }} //setOrderId(row?._id)
                                >
                                  <NotificationImportant
                                    sx={{
                                      color: colors.secondary,
                                      fontSize: "20px",
                                    }}
                                  />
                                </IconButton>
                              </Tooltip>
                            </Box>
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <Tooltip title="Mark Completed">
                                <IconButton
                                  onClick={() => {
                                    setConfirmDialog(!confirmDialog);
                                    setOrderId(row?._id);
                                  }}
                                >
                                  <Recommend
                                    sx={{
                                      color: colors.secondary,
                                      fontSize: "20px",
                                    }}
                                  />
                                </IconButton>
                              </Tooltip>
                            </Box>
                          </Box>
                        ) : (
                          <Typography variant="body2" color="initial" noWrap>
                            <Done sx={{ color: colors.success }} />
                          </Typography>
                        )}
                      </TableCell>
                      <TableCell>
                        <Typography
                          component={"button"}
                          variant="body2"
                          color="initial"
                          noWrap
                          sx={{
                            cursor: "pointer",
                            color: colors.primary,
                            backgroundColor: "transparent",
                            border: "none",
                          }}
                          onClick={() =>
                            navigate("/allocated-pharmacies", {
                              state: { id: row._id, data: row },
                            })
                          }
                        >
                          {row.allocated ? "Allocated" : "Allocate"}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Tooltip title="View">
                          <IconButton
                            onClick={() => navigate(`/order/${row._id}`)}
                          >
                            <Visibility sx={{ color: colors.completed }} />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Table sx={{ marginTop: "12px" }}>
              <TablePagination
                sx={{
                  borderBottom: "none",
                  bgcolor: colors.white,
                  borderRadius: "12px",
                }}
                rowsPerPageOptions={[10, 20, 30]}
                colSpan={12}
                count={count === undefined ? 0 : +count}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    "aria-label": "rows per page",
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </Table>
          </Fragment>
        )}
      </Box>
    </Fragment>
  );
};

export default Order;
