import { get, post, patch, deleted } from '../index'
import VoucherRoutes from './Voucher.Routes';

const VoucherServices = {
  getAllVouchers: async (page, rowsPerPage) => {
    const data = await get(VoucherRoutes.getAllVouchers + `?perPage=${rowsPerPage}` + `&page=${page}`)
    return data
  },
  createVoucher: async (obj) => {
    const data = await post(VoucherRoutes.createVoucher, obj)
    return data
  },
  deleteVoucher: async (id) => {
    const data = await patch(VoucherRoutes.deleteVoucher + `?id=${id}`)
    return data
  },
  blogPicture: async (obj) => {
    const data = await post(VoucherRoutes.getAllBlogs, obj)
    return data
  },
  updateVoucher: async (obj, id) => {
    const data = await patch(VoucherRoutes.updateVoucher + `?id=${id}`, obj)
    return data
  },
}

export default VoucherServices