import React, { Fragment, useEffect, useState } from "react";
import {
  Grid,
  Box,
  MenuItem,
  Menu,
  Avatar,
  Typography,
  Divider,
  Button,
  AppBar,
  Toolbar,
  Badge,
  DialogActions,
  IconButton,
  Dialog,
  DialogContent,
  TextField,
} from "@mui/material";
import {
  CircleNotifications,
  SettingsOutlined,
  LockReset,
  Close,
} from "@mui/icons-material";
import colors from "./../styles/colors";
import { PrimaryButton } from "./../components/Button/Button";
import { useNavigate } from "react-router-dom";
import useAuth from "./../hooks/useAuth";
import { styled, useTheme } from "@mui/material/styles";
import ResetPasswordDialog from "../components/Dialog/ResetPasswordDialog";
import { SuccessToaster } from "../components/Toaster/Toaster";
import { ErrorToaster } from "./../components/Toaster/Toaster";
import AuthServices from "./../apis/Auth/AuthServices";
import { onMessageListener } from "../firebase/firebase";
import NotificationServices from "../apis/Notification/NotificationServices";
import ProfileDialog from "../components/Dialog/ProfileDialog";
import ProfileServices from "../apis/Profile/ProfileServices";
import { baseUrl } from "../../axios";
import { InputField } from "../components/Fields/TextField";
import { useForm } from "react-hook-form";

//* badge style For Notification
const StyledBadge = styled(Badge)(() => ({
  "& .MuiBadge-badge": {
    right: 18,
    top: 6,
    border: `1px solid ${colors.white}`,
  },
}));

const NotificationDialogBox = ({ open, onClose, title }) => {
  const [titleNotification, setTitleNotification] = useState("");
  const [messageNotification, setMessageNotification] = useState("");
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm();
  const TITLE_MAX_CHARACTER = 35;
  const MESSAGE_MAX_CHARACTER = 108;
  // const notifiTitle = watch("notificationTitle") || "";
  // const notifiMessage = watch("notificationMessage") || "";

  let remainingTitleChars = TITLE_MAX_CHARACTER;
  let remainingMessageChars = MESSAGE_MAX_CHARACTER;

  const handleSend = async () => {
    const obj = {
      title: titleNotification,
      body: messageNotification,
    };
    try {
      const result = await NotificationServices.sendNotification(obj);
      const { responseCode, message } = result;
      if (responseCode === 200) {
        SuccessToaster(message);
        onClose();
        setTitleNotification("");
        setMessageNotification("");
      }
    } catch (error) {
      ErrorToaster(error);
    }
  };

  return (
    <Dialog
      data-testId="dialog-box"
      maxWidth="xs"
      open={open}
      sx={{
        "& .MuiDialog-paper": {
          width: "80%",
          height: "auto",
          borderRadius: 2,
          py: { xs: 2, md: 4 },
          px: { xs: 3, md: 6 },
        },
      }}
    >
      <IconButton
        data-testId="close-dialog"
        color="primary"
        onClick={() => onClose()}
        sx={{ position: "absolute", right: 13, top: 13 }}
      >
        <Close />
      </IconButton>
      <DialogContent sx={{ pt: 1 }}>
        <Box display="flex" justifyContent="center" p={1}>
          <Typography variant="h5" fontWeight="bold">
            {title}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        <Grid container spacing={2}>
          <Grid item md={12}>
            <Box>
              <TextField
                fullWidth
                variant="outlined"
                size={"small"}
                label={"Title"}
                placeholder={"Title"}
                value={titleNotification}
                inputProps={{ maxLength: TITLE_MAX_CHARACTER }}
                sx={{
                  "& .MuiFormHelperText-root": { textAlign: "end" },
                }}
                {...register("notificationTitle")}
                onChange={(e) => setTitleNotification(e.target.value)}
                helperText={`${titleNotification.length} / ${remainingTitleChars}`}
              />
            </Box>
          </Grid>
          <Grid item md={12}>
            <Box>
              <TextField
                fullWidth
                multiline
                size={"small"}
                rows={3}
                variant="outlined"
                label={"Message"}
                placeholder={"Message"}
                value={messageNotification}
                inputProps={{ maxLength: MESSAGE_MAX_CHARACTER }}
                sx={{
                  "& .MuiFormHelperText-root": { textAlign: "end" },
                }}
                {...register("notificationMessage", {
                  maxLength: MESSAGE_MAX_CHARACTER,
                })}
                onChange={(e) => setMessageNotification(e.target.value)}
                helperText={`${messageNotification.length} / ${remainingMessageChars}`}
              />
            </Box>
          </Grid>
          <Grid item md={12}>
            <Button onClick={handleSend} variant={"contained"} color="primary">
              Send
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

const Header = () => {
  const { userLogout, userName, picture, mail } = useAuth();
  const navigate = useNavigate();
  const [quoteDialog, setQuoteDialog] = useState(false);
  const [profileDialog, setProfileDialog] = useState(false);
  const [profile, setProfile] = useState("");
  // *Anchor Box
  const [anchorEl, setAnchorEl] = useState(null);
  //Notification Dialog Box
  const [openNotificationDialog, setOpenNotificationDialog] = useState(false);
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event) => {
    if (anchorEl) {
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const logout = async () => {
    const fcmToken = localStorage.getItem("fcmToken");
    try {
      const obj = {
        fcm_token: fcmToken,
      };
      const { data } = await AuthServices.logout(obj);
      if (data === null) return;
      userLogout();
      navigate("/login");
    } catch (error) {
      console.log("🚀 ~ file: Header.js:43 ~ logout ~ error:", error);
    }
  };
  const resetPassword = async (formData, reset) => {
    try {
      const obj = {
        oldPassword: formData.oldPass,
        newPassword: formData.newPass,
      };
      const { data, message } = await AuthServices.reset(obj);
      if (data === null) return;
      SuccessToaster(message);
      reset();
    } catch (error) {
      ErrorToaster(error);
    }
  };

  const updateProfile = async (userData, path) => {
    try {
      const obj = {
        name: userData.name,
        picture: path ? path : profile.picture,
      };
      const { data, message } = await ProfileServices.updateProfile(obj);
      if (data === null) return;
      SuccessToaster(message);
      getProfile();
    } catch (error) {
      ErrorToaster(error);
    }
  };

  const handleNotificationModal = () => {
    setOpenNotificationDialog(true);
  };

  const getProfile = async () => {
    try {
      const { data } = await ProfileServices.getProfile();
      if (data === null) return;
      setProfile(data.result);
    } catch (error) {
      ErrorToaster(error);
      console.log("🚀 ~ file: Header.js:101 ~ getProfile ~ error:", error);
    }
  };

  useEffect(() => {
    getProfile();
  }, []);

  //* For Notification Count
  // const [notificationCount, setNotificationCount] = useState("");
  // const [readNotification, setReadNotification] = useState([]);
  // const [notification, setNotification] = useState({ title: '', body: '', })

  // const getNotificationCount = async () => {
  //   try {
  //     const { data } = await NotificationServices.countNotification()
  //     if (data === null) return
  //     setNotificationCount(data)
  //   }
  //   catch (error) {
  //     ErrorToaster(error)
  //   }
  // }

  // useEffect(() => {
  //   if (notification?.title) {
  //     getNotificationCount()
  //   }
  // }, [notification])

  // const getNotifications = async () => {
  //   try {
  //     const { data } = await NotificationServices.notifications()
  //     if (data === null) return
  //     setReadNotification(data.result)
  //   }
  //   catch (error) {
  //     ErrorToaster(error)
  //   }
  // }

  // onMessageListener()
  //   .then((payload) => {
  //     console.log("🚀 ~ file: Header.js:57 ~ .then ~ payload", payload)
  //     setNotification({
  //       title: payload?.notification?.title,
  //       body: payload?.notification?.body,
  //     });
  //   })
  //   .catch((err) => console.log('failed: ', err));

  return (
    <AppBar sx={{ mb: 20, bgcolor: colors.white, boxShadow: 0 }}>
      <ResetPasswordDialog
        open={quoteDialog}
        onClose={() => setQuoteDialog(!quoteDialog)}
        title={"Reset Password"}
        action={resetPassword}
      />
      <ProfileDialog
        open={profileDialog}
        onClose={() => setProfileDialog(!profileDialog)}
        title={"Admin Profile"}
        action={updateProfile}
        profile={profile}
      />
      <NotificationDialogBox
        open={openNotificationDialog}
        onClose={() => setOpenNotificationDialog(false)}
        title={"Send Notification"}
      />
      <Toolbar>
        <Grid container py={2} px={3} justifyContent={"space-between"}>
          <Grid item xs={3}></Grid>
          <Grid item xs={9}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                float: "right",
                gap: "10px",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  borderRadius: "18px",
                  backgroundImage: colors.primaryGradient,
                }}
                onClick={() => handleNotificationModal()}
              >
                <CircleNotifications />
              </Button>
              <Box
                onClick={(e) => {
                  handleClick(e);
                }}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  p: 1,
                  borderRadius: "22px",
                  backgroundColor: open ? colors.primary : colors.lightGray,
                  "&:hover": {
                    backgroundColor: colors.primary,
                    cursor: "pointer",
                    ".MuiSvgIcon-root": { color: colors.cloud },
                  },
                }}
              >
                <Avatar
                  alt="profile"
                  src={baseUrl + profile.picture}
                  sx={{ width: 24, height: 24 }}
                />
                <SettingsOutlined
                  sx={{
                    color: open ? colors.lightGray : colors.secondary,
                    fontSize: "26px",
                    ml: 2,
                  }}
                />
              </Box>
            </Box>
            {/* <Box sx={{
              display: "flex", alignItems: "center",
              cursor: "pointer", float: "right", pr: 0.5
            }}>
              <Box
                onClick={getNotifications}
                sx={{
                  display: "flex", alignItems: "center", p: 1, borderRadius: "22px",
                  backgroundColor: open ? colors.primary : colors.lightGray,
                  "&:hover": {
                    backgroundColor: colors.primary, cursor: "pointer",
                    ".MuiSvgIcon-root": { color: colors.cloud },
                  },
                }}
              >
                <StyledBadge badgeContent={notificationCount.unreadCount} color="error">
                  <NotificationsNoneTwoTone sx={{ color: colors.secondary, fontSize: "26px", }} />
                </StyledBadge>
              </Box>
            </Box> */}
          </Grid>
        </Grid>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              width: "350px",
              px: 2,
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": { width: 60, height: 60, ml: 1, mr: 1 },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: 500,
              color: colors.black,
              p: 1.5,
            }}
          >
            User Profile
          </Typography>
          <Box
            onClick={() => {
              setProfileDialog(!profileDialog);
            }}
            sx={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              "&:hover": { bgcolor: colors.lightGray, borderRadius: "10px" },
            }}
          >
            <Avatar
              src={baseUrl + profile?.picture}
              sx={{ borderRadius: "50px" }}
            />
            <Box>
              <Typography sx={{ fontSize: "18px", fontWeight: "bold" }}>
                {profile?.name?.charAt(0).toUpperCase() +
                  profile?.name?.slice(1)}
              </Typography>
              <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>
                {profile?.email}
              </Typography>
            </Box>
          </Box>
          <Divider sx={{ my: 1 }} />
          <MenuItem
            sx={{ p: 1, borderRadius: "8px" }}
            onClick={() => {
              setQuoteDialog(!quoteDialog);
            }}
          >
            <Box sx={{ display: "flex" }}>
              <LockReset sx={{ mx: 2, fontSize: "24px" }} />
              {/* <Button sx={{ bgcolor: colors.cloud, mr: 1, borderRadius: '10px' }}>
              </Button> */}
              <Box>
                <Typography sx={{ fontSize: "1rem" }}>
                  {" "}
                  Change Password
                </Typography>
              </Box>
            </Box>
          </MenuItem>
          <Box sx={{ my: 1.5 }}>
            <PrimaryButton
              onClick={logout}
              // data-testId="logout-btn"
              title="logout"
              // onClick={logout}
              fullWidth={true}
            />
          </Box>
        </Menu>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
