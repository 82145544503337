import moment from "moment/moment"

// *Date Format
export const getDate = (date) => {
  const newDate = moment(date).format('YYYY-MM-DD')
  return newDate
}

//* Time Formate
export const getTime = (date) => {
  const newDate = moment(date).format('hh:mm A');
  return newDate
}

//* Time Formate
export const getTimeDate = (date) => {
  const newDate = moment(date).format('MMMM Do, h:mm:ss a');
  return newDate
}
export const getTimeDateYear = (date) => {
  const newDate = moment(date).format('YYYY MMMM Do, h:mm:ss a');
  return newDate
}
export const relativeTime = (date) => {
  const newDate = moment(date).fromNow();
  return newDate
}
