import React, { useEffect, useState } from 'react';
//* Drafts Imports
import { Editor } from 'react-draft-wysiwyg';
import { ContentState, convertToRaw, EditorState, convertFromHTML } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
//* Components & MUI
import { Box, Typography } from '@mui/material';


function TextEditor({ description, getValue, type }) {
  var stateFromHTML = require('draft-js-import-html').stateFromHTML;
  let contentState = stateFromHTML(description ? description : "")

  //* Add default text in Editor
  const [editorState, setEditorState] = useState(
    () => EditorState.createWithContent(contentState ? contentState : ""),
  );

  // //* converting editor text to html
  const rawContentState = convertToRaw(editorState.getCurrentContent());


  const markup = draftToHtml(rawContentState)
  useEffect(() => {
    getValue(markup)
  }, [markup]);

  return (
    <Box sx={{ width: "780px", }}>
      <Editor
        editorState={editorState}
        onEditorStateChange={setEditorState}
        editorStyle={{ height: "160px" }}
        wrapperStyle={{ padding: "2px", border: "1px solid #ccc", borderRadius: "8px" }}
        toolbarStyle={{}}
        toolbar={{
          options: ['inline', 'blockType'],
        }}
        textAlignment={type ? "right" : "left"}
      />
    </Box>
  )
}

export default TextEditor