import { get, post, patch, deleted } from '../index'
import UsersRoutes from './Users.Routes';

const UsersServices = {
  getAllUsers: async (name, page, rowsPerPage) => {
    const data = await get(UsersRoutes.getAllUsers + `?perPage=${rowsPerPage}` + `&page=${page}` + `&search=${name}`)
    return data
  },
  updateUserStatus: async (id, obj) => {
    const data = await post(UsersRoutes.updateUserStatus + `?id=${id}`, obj)
    return data
  },
  deleteUser: async (id) => {
    const data = await patch(UsersRoutes.deleteUser + `?id=${id}`)
    return data
  },
  getDeletedUsers: async (name, page, rowsPerPage) => {
    const data = await get(UsersRoutes.getDeletedUsers + `?perPage=${rowsPerPage}` + `&page=${page}` + `&search=${name}`)
    return data
  },
}

export default UsersServices