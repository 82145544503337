import Transaction from "../views/Transaction/Transaction";
import Login from "./../views/Auth/Login";
const PublicRoutes = [
  {
    path: "/login",
    component: <Login />,
  },
  // {
  //   path: "/processing",
  //   component: <Transaction />,
  // },
];

export default PublicRoutes;
