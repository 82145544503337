import React, { Fragment, useEffect, useState } from 'react'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
//* Required Components
import { Layout } from '../../layout/Layout';
import BreadCrumbs from '../../components/BreadCrumbs/BreadCrumbs';
import ConfirmationDialog from '../../components/Dialog/ConfirmationDialog';
import VoucherServices from '../../apis/Voucher/VoucherServices';
import { Loading } from '../../components/UI/Loader';
import { getDate } from '../../utils/DateFormate';
import { SecondaryButton } from '../../components/Button/Button';
import { ErrorToaster, SuccessToaster } from '../../components/Toaster/Toaster';
import colors from '../../styles/colors';
import styled from '@emotion/styled'
//* MUI Imports
import { useTheme } from '@emotion/react'
import { KeyboardArrowLeft, KeyboardArrowRight, Create, DeleteForever, Visibility, } from '@mui/icons-material'
import { Paper, Box, Grid, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Tooltip, IconButton, tooltipClasses, Avatar, Chip } from '@mui/material';
import PharmacyServices from '../../apis/Pharmacy/PharmacyServices';
import moment from 'moment';
import { baseUrl } from '../../../axios';
import ReplyDialog from '../../components/Dialog/ReplyDialog';



//* custom tooltip
const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 200,
    textAlign: "center",
    background: "#0D253C",
    "&>.MuiTooltip-arrow": {
      "&:before": {
        color: "#0D253C"
      }
    }
  },
});

const tableHead = ['Image', 'Medicine Name', 'Quantity']

const AllocationDetail = () => {

  const navigate = useNavigate()
  const { id } = useParams();

  const [loader, setLoader] = useState(false);
  const [replyDialog, setReplyDialog] = useState(false);
  const [allocationDetail, setAllocationDetail] = useState();

  //* Get All Pharmacy
  const getAllocationDetail = async () => {
    setLoader(true)
    try {
      const { data, responseCode, status } = await PharmacyServices.getAllocationDetail(id)
      if (status === true && responseCode === 200) {
        setAllocationDetail(data.details)
      }
    }
    catch (error) {
      ErrorToaster(error)
    } finally {
      setLoader(false)
    }
  }

  const downloadImage = async (uri) => {
    const imageUrl = uri;
    const response = await fetch(imageUrl);
    const blob = await response.blob();
    const url = window.URL.createObjectURL(new Blob([blob]));

    const link = document.createElement('a');
    link.href = url;
    link.download = 'prescription.jpg';
    link.click();
    window.URL.revokeObjectURL(url);
  };

  useEffect(() => {
    getAllocationDetail()
  }, [])
  return (
    <Fragment>

      <ReplyDialog open={replyDialog} onClose={() => setReplyDialog(false)} id={id} getAllocationDetail={() => getAllocationDetail()} />

      <Box sx={{
        backgroundColor: colors.lightGray, p: 3, borderRadius: "12px", width: "calc(100vw - 370px)"
      }} >
        <Grid container alignItems="center" justifyContent="space-between"
          sx={{
            bgcolor: colors.white, p: 2, borderRadius: "12px",
          }}
        >
          <Grid item xs={6}>
            <Typography variant="body1" fontWeight="bold" color="initial" >Order Id : {allocationDetail?.order_details?.order_id}</Typography>
          </Grid>
          <Grid item xs={6} sx={{ display: "flex", justifyContent: "flex-end" }}>
            <SecondaryButton title="Send Message" onClick={() => setReplyDialog(true)} />
          </Grid>
        </Grid>

        <Box sx={{ my: 2 }} />

        {allocationDetail?.order_details?.medication.length > 0 &&
          <Grid container alignItems="center" justifyContent="space-between"
            sx={{
              bgcolor: colors.white, p: 2, borderRadius: "12px",
            }}
          >
            <Grid item xs={12} sx={{ mb: 1 }}>
              <Typography variant="body1" fontWeight="bold" color="initial" >Medicine List</Typography>
            </Grid>
            <Grid item xs={12}>
              <TableContainer component={Paper} sx={{
                '&::-webkit-scrollbar': {
                  bgcolor: colors.white,
                  height: "8px",
                  borderRadius: "10px",
                  cursor: "pointer"
                },
                '&::-webkit-scrollbar-thumb': {
                  bgcolor: colors.primary,
                  borderRadius: "10px",
                  cursor: "pointer"
                },
                "&.MuiPaper-root": {
                  borderRadius: "12px",
                  mt: 2,
                  boxShadow: 0,
                }
              }}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead >
                    <TableRow sx={{ bgcolor: colors.lightGray }}>
                      {tableHead.map((item, index) =>
                        <TableCell key={index} sx={{ fontWeight: "bold" }} >{item}</TableCell>
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {allocationDetail?.order_details?.medication.map((row, index) => (
                      <TableRow
                        key={index}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                        >
                          <Avatar
                            src={baseUrl + row?.picture}
                            sx={{
                              width: 40,
                              height: 40,
                              border: `1px solid ${colors.textSecondary}`,
                              '.MuiAvatar-img': { objectFit: 'contain' }
                            }}
                          />
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                        >
                          {row.name}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                        >
                          {row.qty}
                        </TableCell>
                      </TableRow>
                    ))}

                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        }
        <Box sx={{ my: 2 }} />

        {allocationDetail?.order_details?.prescription &&
          <Grid container alignItems="center" justifyContent="space-between"
            sx={{
              bgcolor: colors.white, p: 2, borderRadius: "12px",
            }}
          >
            <Grid item xs={6} sx={{ mb: 1 }}>
              <Typography variant="body1" fontWeight="bold" color="initial" >Prescription</Typography>
            </Grid>
            <Grid item xs={6} sx={{ display: "flex", justifyContent: "flex-end" }}>
              <SecondaryButton title="Download" onClick={() => downloadImage(baseUrl + allocationDetail?.order_details?.prescription)} />
            </Grid>
            <Grid item xs={12}>
              <Box
                component="img"
                src={baseUrl + allocationDetail?.order_details?.prescription}
                alt="module" width='250px' height='150px'
              />
            </Grid>
          </Grid>
        }

        <Box sx={{ my: 2 }} />

        {allocationDetail?.messages.length > 0 &&
          <Box sx={{
            bgcolor: colors.white, p: 2, borderRadius: "12px",
          }}>

            {allocationDetail?.messages?.map((item, i) => (
              <Box key={i} sx={{ my: 2 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', px: 2, py: 1, bgcolor: '#F2F9FA', borderTopLeftRadius: '10px', fontWeight: '700', borderTopRightRadius: '10px' }}>

                  <Box>
                    <Typography variant="subtitle1" fontWeight="bold" color="initial" >
                      {item?.sender?.name}
                      <Chip label={item?.sender?.role} sx={{ bgcolor: item?.sender?.role === 'Admin' ? '#53B1BF' : '#8CBE65', color: '#fff', ml: 2, borderRadius: '4px' }} />
                    </Typography>
                  </Box>
                  <Typography variant="body2" color="initial">
                    {moment(item.sent_at).format('dddd MMMM Do YYYY, h:mm:ss a')}
                  </Typography>
                </Box>
                <Box sx={{ px: 2, py: 2, bgcolor: colors.lightGray, borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px' }}>
                  {item.message}
                </Box>
              </Box>
            ))}

          </Box>
        }

      </Box>
    </Fragment>
  )
}

export default AllocationDetail