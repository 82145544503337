const PharmacyRoutes = {
  createPharmacy: "/auth/newPharmacy",
  getPharmacy: "/user/pharmacies",
  updatePharmacy: "/user/pharmacies/update",
  deletePharmacy: "/user/pharmacies/delete",
  getAllocatedPharmacy: "/orders/allocated/by",
  getPharmacyList: "/user/pharmacies/order",
  allocateOrder: "/orders/allocate",
  getAllocationDetail: "/orders/allocated/details",
  SendMessage: "/orders/allocated/sendMessage",
}

export default PharmacyRoutes