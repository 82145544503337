import StaffRoutes from "./Staff.Routes";
import { get, post } from "../index";

const StaffServices = {
  getAllStaff: async (page, perPage) => {
    const data = await get(
      StaffRoutes.getAllStaff + `?page=${page}&perPage=${perPage}`
    );
    return data;
  },
  addStaff: async (obj) => {
    const data = await post(StaffRoutes.addStaff, obj);
    return data;
  },
};

export default StaffServices;
