import { get, post, patch, deleted } from "../../index";
import ProductsRoutes from "./Products.Routes";

const ProductsServices = {
  getAllproducts: async (
    filter_by,
    category_id,
    pharmacy_id,
    offer_id,
    page,
    rowsPerPage
  ) => {
    const data = await get(
      ProductsRoutes.getAllProducts +
      `?filter_by=${filter_by}` +
      `&category_id=${category_id}` +
      `&pharmacy_id=${pharmacy_id}` +
      `&offer_id=${offer_id}` +
      `&page=${page}` +
      `&limit=${rowsPerPage}`
    );
    return data;
  },
  createProduct: async (obj) => {
    const data = await post(ProductsRoutes.createProduct, obj);
    return data;
  },
  updateProduct: async (obj) => {
    const data = await patch(ProductsRoutes.updateProduct, obj);
    return data;
  },
  deleteProduct: async (id) => {
    const data = await deleted(ProductsRoutes.deleteProduct + `?id=${id}`);
    return data;
  },
  uploadImage: async (obj) => {
    const data = await post(ProductsRoutes.uploadImage, obj);
    return data;
  },
  createBulk: async (file) => {
    const data = await post(ProductsRoutes.createBulk, file);
    return data;
  },
};

export default ProductsServices;
