import { Close } from "@mui/icons-material";
import {
  Box,
  Dialog,
  DialogTitle,
  IconButton,
  Grid,
  Typography,
  TextField,
  FormControl,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { PrimaryButton, SecondaryButton } from "../Button/Button";
import { InputField } from "./../Fields/TextField";

function QuoteDialog({ open, onClose, title, action, loading }) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  return (
    <Dialog
      data-testId="dialog-box"
      maxWidth="xs"
      open={open}
      sx={{
        "& .MuiDialog-paper": {
          width: "80%",
          height: "auto",
          borderRadius: 2,
          py: { xs: 2, md: 4 },
          px: { xs: 3, md: 6 },
        },
      }}
    >
      <IconButton
        data-testId="close-dialog"
        color="primary"
        onClick={() => onClose()}
        sx={{ position: "absolute", right: 13, top: 13 }}
      >
        <Close />
      </IconButton>
      <DialogTitle
        sx={{ textAlign: "center", fontSize: "18px", fontWeight: 700 }}
      >
        {title}
      </DialogTitle>
      <Typography
        component={"form"}
        onSubmit={handleSubmit((formData) => action(formData, reset))}
      >
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <InputField
              label="Price"
              type="number"
              min={0}
              error={errors?.price?.message}
              register={register("price", {
                required: "Please Quote a Price.",
              })}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl variant="standard" fullWidth>
              <TextField
                size={"small"}
                variant="outlined"
                label="Message"
                multiline
                rows={3}
                sx={{
                  mb: 2,
                }}
                {...register("message")}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
            <PrimaryButton
              data-testId="yes-btn"
              title="Quote"
              loading={loading}
              type="submit"
            // onClick={() => onClose()}
            />
          </Grid>
        </Grid>
      </Typography>
    </Dialog>
  );
}

export default QuoteDialog;
