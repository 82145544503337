import { get, post, patch, deleted } from '../index';
import AuthRoutes from './Auth.Routes';

const AuthServices = {
  login: async (obj) => {
    const data = await post(AuthRoutes.login, obj);
    return data
  },
  reset: async (obj) => {
    const data = await post(AuthRoutes.reset, obj);
    return data
  },
  logout: async (obj) => {
    const data = await post(AuthRoutes.logout, obj);
    return data
  },
}

export default AuthServices